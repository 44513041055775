import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const ReactAutocompleteModal = ({ items, targetInput, myrefs }) => {
  const updateModal = () => {
    const elems = document.querySelector('#portalModal');
    if (elems) {
      ReactDOM.unmountComponentAtNode(elems);
    }
  };
  ReactDOM.render(
    ReactDOM.createPortal(
      <MainAutoCompleteModal myrefs={myrefs} hideModal={() => updateModal()} targetElement={targetInput}>
        {items}
      </MainAutoCompleteModal>,
      document.querySelector('#portalModal'),
    ),
    document.querySelector('#portalModal'),
  );
};
const calculateDimensions = (streetAddress, modalRef) => {
  const getDimensions = streetAddress.getBoundingClientRect();
  if (modalRef?.current) {
    modalRef.current.style.setProperty('position', 'fixed');
    modalRef.current.style.setProperty('z-index', '9999');
    modalRef.current.style.setProperty('top', `${getDimensions?.y + getDimensions?.height}px`);
    modalRef.current.style.setProperty('left', `${getDimensions?.x}px`);
    modalRef.current.style.setProperty('width', `${getDimensions?.width}px`);
    modalRef.current.style.setProperty('background-color', `#FFF`);
    modalRef.current.style.setProperty('max-height', `8rem`);
    modalRef.current.style.setProperty('overflow-y', `auto`);
    modalRef.current.style.setProperty('overflow-x', `hidden`);
  }
};
const returnElemeent = (eve) => {
  try {
    return eve?.target?.innerHTML?.toLowerCase() === 'show more results' || eve?.target?.innerHTML?.toLowerCase() === 'show less results';
  } catch (e) {
    return false;
  }
};
const MainAutoCompleteModal = (props) => {
  const modalRef = useRef(null);
  const closeKeyPress = (e) => {
    console.log('resizing......', e);
    const elmeHighlited = document.querySelector('.item-highlighted');
    if (elmeHighlited?.innerHTML?.toLowerCase() === 'show more results' || elmeHighlited?.innerHTML?.toLowerCase() === 'show less results') {
      return;
    }
    if (e.key === 'Enter' || e.keyCode === '13') {
      props.hideModal();
    }
  };
  const closeListeners = (eve) => {
    if (!modalRef) {
      return null;
    }
    const inputelem = props.targetElement?.contains(eve.target);
    const listItems = returnElemeent(eve);
    if (!inputelem && !listItems) {
      props.hideModal(false);
    }
  };
  useEffect(() => {
    const resizeInput = new ResizeObserver(() => {
      calculateDimensions(props.targetElement, modalRef);
    });
    if (modalRef.current && props.targetElement) {
      calculateDimensions(props.targetElement, modalRef);
      document.addEventListener('scroll', () => calculateDimensions(props.targetElement, modalRef), true);
      document.addEventListener('keydown', closeKeyPress, true);
      document.addEventListener('click', closeListeners);
      if (props?.myrefs?.current?.refs) {
        // eslint-disable-next-line no-param-reassign
        props.myrefs.current.refs.menu = modalRef.current;
      }
      resizeInput.observe(props.targetElement);
    }
    return () => {
      resizeInput.disconnect();
      document.removeEventListener('scroll', () => calculateDimensions(props.targetElement, modalRef), true);
      document.removeEventListener('keydown', () => closeKeyPress, true);
      document.removeEventListener('click', closeListeners);
    };
  }, [modalRef, props.targetElement]);
  useEffect(() => {}, []);
  return (
    <div id="portalExists" ref={modalRef}>
      {props?.children}
    </div>
  );
};

MainAutoCompleteModal.propTypes = {
  targetElement: PropTypes.any,
  children: PropTypes.any,
  myrefs: PropTypes.any,
  hideModal: PropTypes.any,
};
export default ReactAutocompleteModal;
