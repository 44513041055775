import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addEventListeners, removeEventListeners } from './listeners';
import {
  loggedInExpiredView,
  loggedInExtendedView,
  prospectExpiredView,
  sessionLogout,
  setMainTimeStamp,
  setTimerValue,
  setWarningValue,
} from './action';
import { openModalWindow } from '../modal/action';
import { hideLoader } from '../loader/actions';
import { LQ_VIEWS } from '../../../constants/common';
import { updateCurrentView } from '../../loop-qual-app/actions';
import { getCookie } from '../../../utils/lqServiceUtil';
import { updateQueryParamURL } from '../../../utils/helperUtil';
import { getTimerSessionValue } from '../../../config';

const clearAllTimeouts = (timeouts) => {
  timeouts.forEach((item) => {
    clearTimeout(item);
  });
  window.vzlqSessionTimeouts = [];
};

const TimerInitialize = (props) => {
  const { mode, warning, timerVal } = props;
  const dispatch = useDispatch();
  const warningModal = useSelector((state) => state.sessionTimeout?.loggedInExtendedView);
  const sessionExpired = useSelector((state) => state.sessionTimeout?.loggedInExpiredView);
  const prospectExpired = useSelector((state) => state.sessionTimeout?.prospectExpiredView);
  const staySignedIn = useSelector((state) => state.sessionTimeout?.staySignedIn);
  // const mainTimeStamp = useSelector((state) => state.sessionTimeout?.mainTimeStamp);
  const loopQualApp = useSelector((state) => state.loopQualApp);
  const sessionModalExist = useSelector((state) => state.modalOverlay?.isModalOpened);
  const isLoggedIn = getCookie('loggedIn') || getCookie('userinfo');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isFromLogOut = urlSearchParams.get('fromLqLogout') === 'Y';
  const modes = {
    M: 60,
    H: 3600,
    S: 1,
  };

  const updatePreTimer = () => {
    const d = new Date();
    d.setMinutes(d.getMinutes() + timerVal);
    d.setSeconds(d.getSeconds());
    dispatch(setMainTimeStamp(d.getTime()));
    return d;
  };
  useEffect(() => {
    if (isFromLogOut) {
      dispatch(updateCurrentView(LQ_VIEWS.SESSION_TIMEOUT_VIEW));
      dispatch(loggedInExpiredView());
      openModalWindow(loopQualApp?.homeLQ ? 'HOMELQ' : 'LQAPP', dispatch);
      const updatedURL = updateQueryParamURL('fromLqLogout');
      window.history.replaceState({}, null, updatedURL);
    } else {
      dispatch(setTimerValue(timerVal));
      dispatch(setWarningValue(warning));
      updatePreTimer();
    }
  }, []);

  // useEffect(() => {
  //   const sessionKeep = setInterval(() =>{
  //     sessionKeepAlive();
  //   },warning * modes[mode] * 1000);
  //   return () =>{
  //     clearInterval(sessionKeep);
  //   }
  // },[])
  useEffect(() => {
    if (!window.vzlqSessionTimeouts) {
      window.vzlqSessionTimeouts = [];
    }
    let timeout = null;
    const timeout1 = () =>
      setTimeout(() => {
        console.log('warning Modal');
        dispatch(loggedInExtendedView());
        dispatch(updateCurrentView(LQ_VIEWS.SESSION_TIMEOUT_VIEW));
        openModalWindow(loopQualApp?.homeLQ ? 'HOMELQ' : 'LQAPP', dispatch);
        dispatch(hideLoader());
        // disableEvents = true;
      }, warning * modes[mode] * 1000);

    const timeout2 = () =>
      setTimeout(() => {
        console.log('expiry Modal');
        removeEventListeners(listnerCallback);
        dispatch(updateCurrentView(LQ_VIEWS.SESSION_TIMEOUT_VIEW));
        if (isLoggedIn) {
          sessionLogout(dispatch, loopQualApp);
        } else {
          dispatch(prospectExpiredView());
          openModalWindow(loopQualApp?.homeLQ ? 'HOMELQ' : 'LQAPP', dispatch);
        }
      }, (isLoggedIn ? timerVal - warning : timerVal) * modes[mode] * 1000);
    const listnerCallback = () => {
      // clearTimeout(timeout);
      clearAllTimeouts(window.vzlqSessionTimeouts);
      timeout = isLoggedIn ? timeout1() : timeout2();
      window.vzlqSessionTimeouts.push(timeout);
      console.log('resets timer');
      updatePreTimer();
    };
    if (!isFromLogOut) {
      // console.log('sessionexpired', sessionExpired, warningModal);
      timeout = isLoggedIn ? (warningModal ? timeout2() : timeout1()) : timeout2();
      window.vzlqSessionTimeouts.push(timeout);
      // let disableEvents = false;
      const notPreTimerView = sessionExpired || prospectExpired;
      if (warningModal || notPreTimerView) {
        removeEventListeners(listnerCallback);
        if (notPreTimerView) {
          console.log('clearing previous timer if already session expired');
          removeEventListeners(listnerCallback);
          clearAllTimeouts(window.vzlqSessionTimeouts);
        }
      } else if (!warningModal && !notPreTimerView) {
        removeEventListeners(listnerCallback);
        addEventListeners(listnerCallback);
        if (staySignedIn) {
          listnerCallback();
        }
      }
    }
    return () => {
      // clearTimeout(timeout);
      removeEventListeners(listnerCallback);
    };
  }, [warningModal, sessionExpired, sessionModalExist, prospectExpired, staySignedIn]);
  return <></>;
};

const MainTimerInitialize = (props) => {
  // queryparam: timer_exists - value eg: 3_5, 3 is warning and 5 is expiry to enable queryparam session timeout
  const getTimerData = getTimerSessionValue();
  const { runTimeKey, sessionTimeoutData: sessionTimeout } = props;
  // remove getTimerData handling if production
  useEffect(() => {
    console.log('%c***entering session timeout module***', 'color: green;font-weight:bold;');
    return () => {
      // removing all timeout listeners if any when unmount.
      if (Array.isArray(window.vzlqSessionTimeouts)) {
        clearAllTimeouts(window.vzlqSessionTimeouts);
      }
      console.log('%c***exiting session timeout module***', 'color: red;font-weight:bold;');
    };
  }, []);
  if (!sessionTimeout || !runTimeKey) {
    return <></>;
  }
  const isLoggedIn = getCookie('loggedIn') || getCookie('userinfo');
  // checking run time key
  const checkTimeoutEnabled = runTimeKey?.[isLoggedIn ? 'EnableLoggedInSessionTimeout' : 'EnableProspectSessionTimeout'] === 'Y';
  console.log('rerenders');
  if (sessionTimeout && checkTimeoutEnabled) {
    // enable only if production
    const { TIMER } = sessionTimeout;
    // has to change the logic if production
    const WARNING_VALUE = getTimerData ? Number(getTimerData?.warning_val) : TIMER ? Number(TIMER.WARNING_VALUE) : '';
    const EXPIRED_VALUE = getTimerData ? Number(getTimerData?.expiry_val) : TIMER ? Number(TIMER.EXPIRED_VALUE) : '';
    if (WARNING_VALUE >= EXPIRED_VALUE) return <></>;
    return <TimerInitialize timerVal={EXPIRED_VALUE} warning={WARNING_VALUE} />;
  }
  return <></>;
};

export default memo(
  MainTimerInitialize,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.sessionTimeoutData) === JSON.stringify(nextProps.sessionTimeoutData) &&
    JSON.stringify(prevProps.runTimeKey) === JSON.stringify(nextProps.runTimeKey),
);

TimerInitialize.defaultProps = {
  mode: 'M',
};
TimerInitialize.propTypes = {
  timerVal: PropTypes.any,
  warning: PropTypes.any,
  mode: PropTypes.oneOf(['M', 'S', 'H']),
};
MainTimerInitialize.propTypes = {
  sessionTimeoutData: PropTypes.any,
  runTimeKey: PropTypes.any,
};
