import React from 'react';
import { Button, ButtonGroup, TextLink, TextLinkCaret } from '@vds/buttons';
import { withVDSManager } from '@vds/utilities';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Body, Title } from '@vds/typography';

export const inServiceStyle = `@media screen and (min-width:1232px) and (max-width:1679px){
  h2{
  font-size: 2.75rem;line-height: 2.75rem;}
}`;

export const inServiceNegativeStyle = `@media screen and (min-width:1232px) and (max-width:1679px){
margin-bottom: 56px;  
`;

export const LqHeading = styled.div`
  ${(props) => props.$customStyle || ''}
`;

export const LqSubHeading = styled.div`
  margin-top: ${(props) => props.$spacing};
`;

// const ButtonCTA = styled.div`
//   margin-top: ${(props) => props.spacing};
//   @media screen and (max-width: 767px) {
//     margin-top: ${(props) => props.mobileSpacing};
//   }
// `;

export const HomeLQWrapper = styled.div`
  * {
    font-family: ${(props) => props.font?.family};
  }
  width: 100%;
  div[class^='Spacing-LQDS'] {
    > button[class^='StyledButton-VDS'] {
      &:hover,
      &:focus,
      &:active {
        background-color: ${(props) => props?.$secondaryColor};
        border-color: ${(props) => props?.$secondaryColor};
        color: revert;
        text-decoration: none;
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.0625rem;
      }
    }
  }
  div[class^='CTASize-LQDS'] {
    button[class^='StyledButton-VDS'] {
      &:hover,
      &:focus,
      &:active {
        background-color: ${(props) => props?.$secondaryColor};
        border-color: ${(props) => props?.$secondaryColor};
        color: revert;
        text-decoration: none;
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.0625rem;
      }
    }
  }
  div[class^='ButtonGroupWrapper-VDS'] {
    div[class^='ButtonWrapper-VDS'] {
      &:last-child {
        button {
          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            border-color: ${(props) => props?.$secondaryColor};
            color: ${(props) => props?.$secondaryColor};
            box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.0625rem;
          }
        }
      }
      &:first-child {
        button {
          &:hover,
          &:focus,
          &:active {
            background-color: ${(props) => props?.$secondaryColor};
            border-color: ${(props) => props?.$secondaryColor};
            color: #000;
            text-decoration: none;
            box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.0625rem;
          }
        }
      }
    }
  }

  position: relative;
  z-index: 80 !important;
  height: ${(props) => props.$height};
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  background-image: url(${(props) => props.$background?.mobile});
  ${(props) =>
    props.$breakPoints
      ? `
        @media screen and (min-width: ${props?.$breakPoints?.tablet}){
        background-image: url(${props.$background?.tablet});
          }  
       @media screen and (min-width: ${props.$breakPoints?.desktop}){
        background-image: url(${props.$background?.desktop});
        }
        
     
    `
      : ``}
  background-size: cover;
  background-repeat: no-repeat;
  ${(props) =>
    props.$dimensions
      ? `
      background-size: contain;
      ${
        props.$dimensions.mobile
          ? `
        @media screen and (max-width:767px){
      --cH: calc( 100vw * ${props.$dimensions.mobile.height} );
      --dH: calc( var(--cH) / ${props.$dimensions.mobile.width} );
      background-size: cover;
      height: var(--dH);
  }`
          : ``
      }
      ${
        props.$dimensions.tablet
          ? `
        @media screen and (min-width: ${props?.$breakPoints?.tablet}){
      --cH: calc( 100vw * ${props.$dimensions.tablet.height} );
      --dH: calc( var(--cH) / ${props.$dimensions.tablet.width} );
      height: var(--dH);

  }`
          : ``
      }
  ${
    props.$dimensions.desktop
      ? `
        @media screen and (min-width: ${props?.$breakPoints?.desktop}){
      --cH: calc( 100vw * ${props.$dimensions.desktop.height} );
      --dH: calc( var(--cH) / ${props.$dimensions.desktop.width} );
      height: var(--dH);

  }
      @media screen and (min-width: ${props?.$breakPoints?.minDesktop}){
      --cH: calc( 1600px * ${props.$dimensions.desktop.height} );
      --dH: calc( var(--cH) / ${props.$dimensions.desktop.width} );
      height: var(--dH);

  }`
      : ``
  }
  
    `
      : ``}

  ${(props) =>
    props.$layoutWidth
      ? `@media screen and (min-width: calc( ${props.$layoutWidth} + ${props.$layoutPadding})) {
    max-width: ${props.$layoutWidth};
    margin: 0 auto;
    border-radius: 24px;
  }`
      : ``}
  @media screen and (max-width: 767px) {
    height: auto;
    padding: 25px 0px 320px 0px;
    ${(props) => (props.$noBanner ? `align-items: flex-start;padding: 25px 0px 25px 0px; min-height: 60vh;max-height: 90vh;` : ``)}
    background-position: center bottom;
  }
`;

export const AlignContent = styled.div`
  height: auto;
  position: static !important;
  max-width: ${(props) => props.$width};
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 1272px) {
    padding: 0px 20px;
    max-width: 100%;
  }
  ${(props) => props.$customStyle || ''}
  @media screen and (max-width: 767px) {
    padding: 0px 16px;
    max-width: 100%;
  }
`;

export const SpanSpacing = styled.span`
  margin: ${(props) => props.margin || ''};
  padding: ${(props) => props.padding || ''};
  display: ${(props) => (props.flex ? 'inline-flex' : 'inline-block')};
  ${(props) => props.orderrideAnchor || ''};
`;
export const Spacing = styled.div`
  margin: ${(props) => props.margin || ''};
  padding: ${(props) => props.padding || ''};
  width: 100%;
  ${(props) =>
    props.$breakpoint
      ? `
      @media screen and (max-width: ${props.$breakpoint}) {
        margin: ${props.$breakPoinStyles?.margin || ''};
        padding: ${props.$breakPoinStyles?.padding || ''};
        width: ${props.$breakPoinStyles?.width || '100%'}
      }`
      : ''}
  @media screen and (max-width: 767px) {
    margin: ${(props) => props.mobileMargin || ''};
    padding: ${(props) => props.mobilePadding || ''};
    height: ${(props) => props.$height || ''};
    width: 100%;
    ${(props) => (props.$mobileTopAuto ? 'margin-top: auto;' : '')}
    ${(props) => (props.$mobileFlex ? 'display: flex; flex-direction: column;' : '')}
    ${(props) => (props.$bottomFixed ? `position: fixed;bottom: ${props.$bottom || '16px'}; width: calc(100% - 32px);` : ``)}
  }
`;
export const VDSManagerBody = withVDSManager((props) => {
  const { viewport, ...rest } = props;
  const restData = rest;
  if (viewport === 'desktop') {
    restData.size = 'large';
  } else {
    restData.size = 'medium';
  }
  return <Body {...restData} />;
});

export const VDSManagerTitle = withVDSManager((props) => {
  const { viewport, ...rest } = props;
  console.log('viewport', viewport);
  const restData = rest;
  /*
  properties used in vds..

  2XLarge - addressQualificaiton view title
  XLarge - nonAddressQualificaiton view title
  */
  const desktopFont = rest.subTitle ? 'small' : props.$addressQualfication ? '2XLarge' : 'XLarge';
  restData.size = desktopFont;
  rest.viewport = 'desktop';
  if (viewport === 'desktop') {
    // do the code
  } else if (viewport === 'tablet') {
    restData.viewport = 'mobile';
    restData.size = rest.subTitle ? desktopFont : '2XLarge';
  } else {
    restData.viewport = 'mobile';
    restData.size = rest.subTitle ? desktopFont : 'XLarge';
  }
  return <Title {...restData} />;
});

export const VdsManagerButton = withVDSManager((props) => {
  const { desktopWidth, mobileWidth } = props.btnProps || {};
  console.log('viewport', props.viewport);
  const btnWidth = props.viewport === 'desktop' || props.viewport === 'tablet' ? desktopWidth : mobileWidth;
  const childrenView = props?.btnProps?.use === 'primary' ? <ColorNode $color={props.primaryColor}>{props.children}</ColorNode> : props.children;
  return (
    <Button {...props.btnProps} width={btnWidth}>
      {childrenView}
    </Button>
  );
});

export const ColorNode = styled.span`
  color: ${(props) => props.$color};
  ${(props) => (props.letterNormal ? `letter-spacing:normal;` : '')};
`;
export const VdsManagerButtonGroup = withVDSManager((props) => {
  const { desktopWidth, mobileWidth, data, viewport, ...rest } = props;
  let buttons = props?.data || [];
  const btnWidth = props.viewport === 'desktop' || props.viewport === 'tablet' ? desktopWidth : mobileWidth;
  buttons = buttons.map((item) => {
    const item1 = item;
    item1.width = item.width || btnWidth;
    item1.children =
      item1.use === 'primary' ? (
        <ColorNode letterNormal={rest.letterSpacing} $color={rest.primaryColor}>
          {item1.children}
        </ColorNode>
      ) : (
        <ColorNode letterNormal={rest.letterSpacing}>{item1.children}</ColorNode>
      );
    return item1;
  });
  return <ButtonGroup viewport={viewport} data={buttons} {...rest} surface={rest.surface || 'dark'} />;
});

export const HomeGrid = styled.div`
  display: flex;
  align-items: ${(props) => props.align || ''};
  ${(props) =>
    props.breakpoint
      ? `@media screen and (max-width: ${props.breakpoint}) {
    flex-wrap: wrap;
  }`
      : ''}
  @media screen and (max-width: 767px) {
    flex-wrap: ${(props) => props.wrap || 'wrap'};
  }
`;

export const HomeCol = styled.div`
  width: ${(props) => (props?.desktopAuto ? 'auto' : props?.width ? props.width : '100%')};
  ${(props) =>
    props.breakpoint
      ? `@media screen and (max-width: ${props.breakpoint}) {
    width: ${props.width || '100%'} ;
    padding: ${props.padding || ''}};
  }`
      : ''}
  @media screen and (max-width: 767px) {
    width: ${(props) => props?.mobileWidth || '100%'};
    padding: 0px;
  }
`;
export const TextStyle = styled.div`
  white-space: ${(props) => (props.noWrap ? 'nowrap' : '')};
  @media screen and (max-width: 767px) {
    white-space: normal;
  }
`;

export const InputWrapper = styled.div`
  width: ${(props) => props.width};
  @media screen and (min-width: 1232px) {
    width: 437px;
  }
  ${(props) =>
    props.breakPoint
      ? `@media screen and (max-width: ${props.breakPoint}) {
    width: ${props.tabletWidth};
  }`
      : ``}

  p {
    color: ${(props) => props.color || ''};
  }
  label {
    p {
      color: ${(props) => props.color || ''};
      span {
        color: ${(props) => (!props.tooltip === 'dark' && props.color) || ''};
        svg {
          fill: ${(props) => props.color || ''};
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const SkeletonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.bgColor || 'transparent'};
`;

export const MainWrapper = styled.div`
  ${(props) =>
    props.$breakPoints
      ? `
      @media screen and (min-width: ${props.$breakPoints?.tablet}){
      width: ${props?.$width?.tablet};
      }
  @media screen and (min-width: ${props.$breakPoints?.desktop}){
    width: ${props?.$width?.desktop};
    }
  ${
    props.$noBanner
      ? `
  @media screen and (min-width: ${props.$breakPoints?.tablet}){
      width: 100%;
      }
  @media screen and (min-width: ${props.$breakPoints?.desktop}){
    width: 100%;
    }
  `
      : ``
  }
  @media screen and (max-width: 767px){
  width: 100%;
  
  }
  `
      : ``}
`;

export const manageBtnProps = (btns, desktopWidth = 'auto', mobileWidth = '100%') => {
  const btnData = btns;
  btnData.children = btns.Btntext;
  if (btns.primary) {
    btnData.use = 'primary';
  }
  if (btns.secondary) {
    btnData.use = 'secondary';
  }
  if (btns.datatracker) {
    btnData['data-track'] = btns.datatracker;
  }
  btnData.size = 'large';
  btnData.onClick = btns.clickBtn;
  btnData.desktopWidth = desktopWidth;
  btnData.mobileWidth = mobileWidth;
  return btnData;
};
export const HOMELQSuccessWrapper = (props) => {
  const { buttons = [], links = [], spacing, desktopWidth = 'auto', mobileWidth = '100%', primaryColor } = props;
  const singleBtn = buttons?.length === 1 ? manageBtnProps(buttons[0], desktopWidth, mobileWidth) : null;
  const multiBtn = buttons?.length > 1 ? buttons.map((item) => manageBtnProps(item, desktopWidth, mobileWidth)) : null;
  return (
    <>
      {singleBtn && (
        <Spacing padding={`${spacing.xxxLarge} 0px 0px 0px`} mobilePadding={`${spacing.xxLarge} 0px 0px 0px`}>
          <VdsManagerButton primaryColor={primaryColor} btnProps={singleBtn}>
            {singleBtn.children}
          </VdsManagerButton>
        </Spacing>
      )}
      {multiBtn && <VdsManagerButtonGroup primaryColor={primaryColor} btnProps={multiBtn} />}
      {links &&
        links?.map((item) => {
          const DecideLinkComp = item.isCaret ? TextLinkCaret : TextLink;
          return (
            <Spacing
              padding={`${spacing.xxxLarge} 0px 0px 0px`}
              $breakpoint={item?.spacingBreakPoints?.breakpoint}
              $breakPoinStyles={item?.spacingBreakPoints?.breakPoinStyles}
              mobilePadding={`${spacing.xxLarge} 0px 0px 0px`}
            >
              <Body color={item.color} size="large">
                <DecideLinkComp {...item}>{item.linkText}</DecideLinkComp>
              </Body>
            </Spacing>
          );
        })}
    </>
  );
};

HOMELQSuccessWrapper.propTypes = {
  spacing: PropTypes.any,
  desktopWidth: PropTypes.any,
  mobileWidth: PropTypes.any,
  buttons: PropTypes.any,
  links: PropTypes.any,
  primaryColor: PropTypes.any,
};
