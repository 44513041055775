/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isMYVZWAPP } from '../../utils/lqServiceUtil';
import { greyOut, greyRemove } from '../address-qualification/actions';
import { getResponsiveLQ } from '../../config';

const feature = document.getElementById('LQwidgetIntegration');
const fiosBp = document.getElementById('fiosLqIntegration');
const plansvisit = false;

const headerRoot = document.getElementById('vz-gh20');
const headerAem =
  document.getElementsByClassName('header') && document.getElementsByClassName('header')[0] ? document.getElementsByClassName('header')[0] : '';

const Wrapper = styled.div`
  position: ${(props) => (feature ? 'relative' : props.position ? props.position : 'static')};
  top: ${(props) =>
    props.isSticky &&
    document.getElementsByClassName('gnav20-promo-ribbon')[0] &&
    getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]) &&
    getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]).display !== 'none'
      ? '160px'
      : props.isSticky
      ? props.mq_lg?.topPosition
      : props.offsetHeight
      ? `${props.offsetHeight}px`
      : '0px'};
  width: 100%;
  @media (min-width: 768px) {
    min-height: 72px;
  }
  background-color: ${(props) => props?.colors?.bgColor || props.backgroundColor || '#000000'};
  z-index: 899;
  @media (min-width: ${(props) => (props.mq_lg && props.mq_lg?.breakPoint) || '992px'}) {
    top: ${(props) =>
      props.isSticky &&
      document.getElementsByClassName('gnav20-promo-ribbon')[0] &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]) &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]).display !== 'none'
        ? '165px'
        : props.isSticky
        ? props.mq_lg?.topPosition
        : props.offsetHeight
        ? `${props.offsetHeight}px`
        : '0px'};
  }
  @media (min-width: 320px) and (max-width: 1020.5px) {
    top: ${(props) =>
      props.isSticky &&
      document.getElementsByClassName('gnav20-promo-ribbon')[0] &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]) &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]).display !== 'none'
        ? '117px'
        : props.isSticky
        ? props.default?.topPosition
        : props.offsetHeight
        ? `${props.offsetHeight}px`
        : '0px'};
  }
  @media (min-width: 320px) and (max-width: 767px) {
    top: ${(props) =>
      props.isSticky &&
      document.getElementsByClassName('gnav20-promo-ribbon')[0] &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]) &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]).display !== 'none'
        ? '133px'
        : props.isSticky
        ? props.default?.topPosition
        : props.offsetHeight
        ? `${props.offsetHeight}px`
        : '0px'};
  }
  @media (max-width: 280px) {
    top: ${(props) =>
      props.isSticky &&
      document.getElementsByClassName('gnav20-promo-ribbon')[0] &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]) &&
      getComputedStyle(document.getElementsByClassName('gnav20-promo-ribbon')[0]).display !== 'none'
        ? '125px'
        : props.isSticky
        ? props.default?.topPosition
        : props.offsetHeight
        ? `${props.offsetHeight}px`
        : '0px'};
  }
`;
export default class StickyBar extends React.Component {
  constructor(props) {
    super(props);
    this.stickyBarRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    const isStickyGNav = !!(props.options && props.options.sticky);
    this.responsiveLQMini = getResponsiveLQ() === 'Y';
    this.state = {
      offsetHeight: 0,
      position: this.responsiveLQMini ? 'sticky' : fiosBp ? 'relative' : feature || plansvisit ? '' : isStickyGNav ? 'fixed' : 'static',
      isonLoad: true,
      isSticky: isStickyGNav,
      timeStamp: null,
    };
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad);
    window.addEventListener('scroll', this.handleScroll, true);
    this.setState({ timeStamp: new Date().getTime() });
  }

  componentDidUpdate() {
    if (this.props.colors && !this.props.loopQualApp.hideFccMiniLq && !window.miniResizeObserver) {
      const headerHeight = document.querySelector('#vz-gh20');
      const lqWidgetComp = document.querySelector('.lqWidgetComp');
      const Mini_Lq = document.getElementsByClassName('minilq')[0];
      console.log('Mini_Lq', Mini_Lq, this.props.colors);
      if (typeof ResizeObserver !== 'undefined') {
        const ro = new ResizeObserver((entries) => {
          console.log(entries, 'count');
          for (const entry of entries) {
            const cr = entry.contentRect;
            if (document.querySelector('.lqWidgetComp')) {
              document.querySelector('.lqWidgetComp').style.setProperty('height', `${cr.height}px`, 'important');
              if (isMYVZWAPP() && feature) {
                const mini = document.getElementsByClassName('minilq')[0];
                if (mini) {
                  mini.style.setProperty('top', '0px', 'important');
                }
              }
              console.log('Element:', entry.target);
              console.log(`Element size: ${cr.width}px x ${cr.height}px`);
              console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
            }
          }
        });
        const headerO = new ResizeObserver((entries) => {
          console.log(entries, 'count');
          for (const entry of entries) {
            const cr = entry.contentRect;
            if (document.querySelector('.lqWidgetComp')) {
              console.log('vzHeight', cr.height);
              const mini = document.getElementsByClassName('minilq')[0];
              if (this.responsiveLQMini) {
                lqWidgetComp.style.setProperty('position', 'relative');
              }
              if (mini) {
                const positionTop = feature ? 0 : cr.height;
                console.log('minILQ', mini);
                mini.style.setProperty('top', `${positionTop}px`, 'important');
              }
            }
          }
        });

        if (Mini_Lq && lqWidgetComp) {
          window.miniResizeObserver = true;
          const path = window.location.pathname;
          // progressive plan lq focus
          if ((window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit' || path?.includes('ui/cpc')) && !window.vzlqw?.planLQFocus) {
            if (
              path.includes('/sales/home/progressiveplan') ||
              path.includes('/sales/home/popularplans') ||
              path.includes('/sales/home/streamlineordering') ||
              path.includes('ui/cpc')
            ) {
              const element = document.getElementById('vz-gh20');
              if (element) {
                element.style.zIndex = 999;
                element.style.position = path?.includes('ui/cpc') ? 'relative' : 'sticky';
                element.style.setProperty('top', '0px', 'important');
              }
            }
            greyOut();
            if (window?.vzlqw) {
              window.vzlqw.planLQFocus = true;
            }
            setTimeout(() => {
              const streetInput = document.querySelector('#streetaddress');
              if (streetInput) {
                streetInput.focus();
              }
            }, 500);
          }
          ro.disconnect();
          ro.unobserve(Mini_Lq);
          ro.observe(Mini_Lq);
          if (headerHeight) {
            headerO.disconnect();
            headerO.unobserve(headerHeight);
            headerO.observe(headerHeight);
          } else {
            const mini = document.getElementsByClassName('minilq')[0];
            if (mini && isMYVZWAPP()) {
              mini.style.setProperty('top', `0px`, 'important');
            }
          }
        }
      }
    } else {
      window.miniResizeObserver = false;
    }
  }

  componentWillUnmount() {
    window.miniResizeObserver = false;
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('load', this.handleLoad);
  }

  getOffsetHeight = (element) => {
    let offsetHeight = 0;
    const style = getComputedStyle(element);
    offsetHeight = element.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
    return offsetHeight;
  };

  handleLoad = () => {
    const Mini_Lq = document.getElementsByClassName('minilq')[0];
    if (isMYVZWAPP()) {
      Mini_Lq.style.top = 0;
    }
  };

  expandCollapse = (e) => {
    e.preventDefault();
    try {
      if (e && e.type && e.type === 'scroll') {
        const windowTop = document.documentElement.scrollTop;
        const el = document.getElementById('expand-contract');
        if (windowTop > 200 && el && el.className === 'expanded expanded-unit-address') {
          el.className = 'collapsed';
        }
        if (windowTop < 200 && el) {
          el.className = 'expanded expanded-unit-address';
        }
      }
    } catch (ex) {
      console.log('Error in expandCollapse');
    }
  };

  MoveToHeaderFooter = () => {
    try {
      const mini = document.getElementsByClassName('minilq');
      const windowTop = document.documentElement.scrollTop;
      if (windowTop > 200 && mini && mini[0] && mini[0].style) {
        mini[0].style.inset = 'auto 0px 0px auto';
      }
      if (windowTop < 200 && mini && mini[0] && mini[0].style) {
        mini[0].style.inset = '';
      }
    } catch (e) {
      console.log('Error in MoveToHeaderFooter');
    }
  };

  handleScroll = (e) => {
    let HideLq = false;
    if (this.props?.addressLookup?.streetInput === '') {
      HideLq = true;
    }
    greyRemove(HideLq);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isMYVZWAPP()) {
      // mobile device expand collapse
      this.expandCollapse(e);
    }
    if (
      this.props.loopQualApp.lq4 &&
      this.props.loopQualApp.runTimeKey &&
      this.props.loopQualApp.runTimeKey.EnableHeaderFooter &&
      this.props.loopQualApp.runTimeKey.EnableHeaderFooter === 'Y' &&
      !window.location.href.includes('fios-fastest-internet/bundles')
    ) {
      this.MoveToHeaderFooter();
    }
    const el = document.getElementsByClassName('minilq')[0];
    let isPositionFixed = false;
    if (el) {
      isPositionFixed = getComputedStyle(el).getPropertyValue('position') === 'fixed';
    }
    let offsetHeight = 0;
    const gnavHeader = document.querySelector('#vz-gh20');
    if (headerAem) {
      offsetHeight += this.getOffsetHeight(headerAem);
    } else if (gnavHeader) {
      offsetHeight += this.getOffsetHeight(gnavHeader);
    }
    const promoRibbon = document.querySelector('#custom-ribbon');
    if (!headerRoot && promoRibbon) {
      offsetHeight += this.getOffsetHeight(promoRibbon);
    }
    if (this.responsiveLQMini) {
      this.setState({ offsetHeight, position: 'sticky', isonLoad: false });
    } else if (offsetHeight && !this.state.isSticky) {
      let { position } = this.state;
      if (document.documentElement.scrollTop > offsetHeight) {
        if (!isPositionFixed) {
          position = 'fixed';
        }
        offsetHeight = 0;
      }
      if (document.documentElement.scrollTop <= offsetHeight) {
        if (isPositionFixed) {
          position = 'static';
        }
        offsetHeight -= document.documentElement.scrollTop;
      }
      this.setState({ offsetHeight, position, isonLoad: false });
    } else if (feature || fiosBp || plansvisit) {
      this.setState({ offsetHeight, position: fiosBp ? 'relative' : '', isonLoad: false });
    } else {
      this.setState({ offsetHeight, position: 'fixed', isonLoad: false });
    }
  };

  render() {
    const { layout, styling, colors, loopQualApp, isInterChange } = this.props;
    const windowTop = document.documentElement.scrollTop;
    const scrollDown = windowTop && windowTop > 200;
    // console.log('stickyProps', colors);
    return colors ? (
      <>
        <div className="sticky" tabIndex="-1" onKeyUp={() => greyRemove()} onKeyDown={() => greyRemove()} onClick={() => greyRemove(true)} />
        <Wrapper
          className={`minilq ${isInterChange ? 'interchange_sticky' : ''}`}
          // eslint-disable-next-line jsx-a11y/aria-role
          id={
            scrollDown &&
            loopQualApp &&
            loopQualApp.lq4 &&
            loopQualApp.runTimeKey &&
            loopQualApp.runTimeKey.EnableHeaderFooter &&
            loopQualApp.runTimeKey.EnableHeaderFooter === 'Y'
              ? 'footerexp'
              : ''
          }
          {...layout}
          {...loopQualApp}
          {...this.state}
          {...styling}
          colors={colors}
          ref={(el) => (this.stickyBarRef = el)}
        >
          {this.props.children}
        </Wrapper>
      </>
    ) : (
      <></>
    );
  }
}

StickyBar.propTypes = {
  layout: PropTypes.string,
  styling: PropTypes.string,
  colors: PropTypes.any,
  options: PropTypes.string,
  children: PropTypes.string,
  loopQualApp: PropTypes.string,
  addressLookup: PropTypes.string,
  isInterChange: PropTypes.any,
};
