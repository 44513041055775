import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { getCurrentBaseView } from './view-resolver';
import { Flowtype, LQ_VIEWS, Platforms, Pages } from '../../constants/common';
import * as Action from './actions';
import { validateToken, getVendorDetails, updateVendorDetails, validateVendor, processSaveCartInvoked } from '../order-fios/actions';
import {
  genarateToken,
  checkTokenValidity,
  generateVisitorId,
  generateVisitId,
  checkLoggedIn,
  checkLoggedInStatus,
  checkLoggedInStatusUserInfo,
  checkFiosVzt,
  redirectToPlansPage,
  redirectToFiosPlansPage,
  getWidgetHost,
  setToStorage,
  getCookie,
  validateVendorToken,
  getInHomeTWSURL,
  isMYVZWAPP,
  getFromStorage,
  prefetchHereMapScript,
  getLoggedInTwsLQ,
  wifiBackupFromFios,
  wifiBackupFromFiosAddress,
} from '../../utils/lqServiceUtil';
import {
  checkNetworkAvailability,
  updateHelpView,
  lqsTagTriggered,
  inVokeLqCheckNetworkAvailability,
  greyOut,
  autoLocationPopulate,
} from '../address-qualification/actions';
import Loader from '../common/loader/Loader';
import MiniLq from '../mini-lq';
import ModalOverlay from '../common/modal/modalOverlay';
import SignIn from '../common/signIn';
import { openModalWindow, closeModalWindow, existingCustomerModal } from '../common/modal/action';
import config, {
  getFlowType,
  getLQPlatform,
  getRenderSettings,
  getTwsFlow,
  getCrossSell,
  getSourceInfo,
  getFiosInquire,
  getTwsStickyLQ,
  getHomeLQSettings,
  canApplySessionTimeout,
  getResponsiveLQ,
} from '../../config';
import {
  getCookieVisitIdAndVisitorIdNoParam,
  getRuntimekey,
  getPdm,
  comboLqint,
  locusIDfetch,
  fetchAuthDetailsDAW,
  getAddressInfoAgain,
} from '../../services/lqService';
import { stickyBannerContent } from '../../constants/lq-constants';
import {
  invalidAddressError,
  setRevisitAddress,
  setStreetError,
  setStreetGeoError,
  onStreetSelection,
  setRevisitScenario,
} from '../address-lookup/actions';
import { orderNowFiveGNsa, invokeUCAbandonedCart } from '../order-fiveg-home/actions';
import { getVZDLInit } from '../../utils/vzdl';
import { sendDataVzTagLqs, openViewVzTag, pageViewPageVzTag, linkVzTag, sendDataVzTagMessage } from '../../utils/unifiedVzTag';
import DomComponent from '../common/DomComponent';
import { checkLoggedInMV } from '../move-lq/action';
import { checkLoggedInBulkQual, resetBulkDetails, checkLoggedInBulkQual_ReFetch } from '../bulk-lq/action';
import { logMetrics } from '../../services/logService';
import { hideLoader, showLoader } from '../common/loader/actions';
import { configLQTheme } from './themeConfig';
import MinLQSkeleton from '../skeleton-bar/MiniLQ';
import { PrefetchHomeInternet, PrefetchFiosOrdering, PrefetchFiosOrderingCriticalAssets } from '../common/prefetchAssets';
import { resetSessionView } from '../common/SessionTimeout/action';
import { updateHideMiniLq } from './actions';
import HomeLQMain from './homelq';
import { getDomainUrl, prodEspanolDomain } from '../../constants/api-urls';
import TimerInitialize from '../common/SessionTimeout/TimerInitialize';

class LoopQualApp extends React.PureComponent {
  // Branch Develop 27 Dec 1:33 PM IST
  constructor(props, context) {
    super(props, context);
    this.state = {
      colors: null,
    };
    this.props.dispatch(Action.resetLboModal());
    this.props.dispatch(hideLoader());
    this.props.dispatch(Action.updateSkeleton(true));
    this.props.dispatch(Action.updateHomeLQ(null));

    const revisitAddress =
      this.props.customerStreetDetails && this.props.customerStreetDetails.disprawstr
        ? this.props.customerStreetDetails.disprawstr
        : this.props.revisitAddress;
    const scenario = ['NOT_QUALIFIED', 'FIOS_QUALIFIED', '5G_HOME_QUALIFIED', 'LTE_HOME_QUALIFIED', '5G_CBAND_HOME_QUALIFIED'];
    const isRevisit = scenario.includes(sessionStorage.getItem('LqQualified'));
    const isQualified = scenario.includes(props.addressLookup.revisitAddressQualified);
    if (revisitAddress) {
      this.props.dispatch(setRevisitAddress(revisitAddress));
    }
    if (isRevisit || isQualified) {
      this.props.dispatch(setRevisitScenario(true));
    } else {
      this.props.dispatch(setRevisitScenario(false));
    }
    this.onCheckAvailabilityClick = this.onCheckAvailabilityClick.bind(this);
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.retailQrCode = urlSearchParams.has('retail-qr-code');
    if (!this.props.loopQualApp.staticContent) {
      Action.getLoopQualAppStaticContent(this.props.dispatch);
    }
    this.isLqsCompleted = !!(
      this.props.addressQualification.lqsTagTriggered ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW ||
      this.props.addressQualification.lqResult.fiosExtremeHappy
    );
    this.isTwsFilterdFlow = getTwsStickyLQ() === 'Y';
    this.isResponsiveLQMini = getResponsiveLQ() === 'Y';
    this.isResponsiveLQ = getSourceInfo() === 'ResponsiveLQ' || this.isResponsiveLQMini || urlSearchParams.get('type') === 'ResponsiveLQ';
    this.isResponsiveLQEmbeded = urlSearchParams.get('type') === 'ResponsiveLQEmbeded';
    this.plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
  }

  async componentDidMount() {
    const srcInfo = getSourceInfo();

    const isLoggedIn =
      srcInfo && srcInfo === 'digitalCombo' && getCookie('role') === 'guest'
        ? false
        : checkLoggedInStatus(this.props.dispatch, this.props.loopQualApp);
    const isLoggedInUserInfo = checkLoggedInStatusUserInfo(this.props.dispatch);
    const isFiosVzt = checkFiosVzt(this.props.dispatch);

    sessionStorage.removeItem('MLP_Plan_Details');
    sessionStorage.removeItem('MLP_PopularPlan');
    this.props.dispatch(Action.updateAutoGeo(false));
    processSaveCartInvoked(this.props.dispatch, false);
    setToStorage('Fwa_MN', 'Y');
    document.cookie = `Fwa_MN=Y;path=/`;
    Action.clearBlankLoaderView(this.props.dispatch, this.props.loopQualApp, this.props.addressQualification);
    window.sessionStorage.removeItem('clearDetailsLQ');
    const twsSrcInfo = getSourceInfo();
    // resetting the fios existing loggedin session if any
    sessionStorage.removeItem('existingFiosLoggedIn');
    const params = new URLSearchParams(window.location.search);
    // Add logic to set PlanName based on query string
    const planName = params.get('planName');
    if (planName) {
      const planMapping = {
        '2Gig': 'R3582',
        '1Gig': 'R3583',
        '500Mbps': 'R3585',
        '300Mbps': 'R3587',
      };
      const planId = planMapping[planName];
      if (planId) {
        const frameSession = { platform: 'fios', dataType: 'internet', planId, planName };
        setToStorage('MLP_Plan_Details', JSON.stringify(frameSession));
      } else if (planName === 'popular') {
        setToStorage('MLP_PopularPlan', 'Y');
      } else {
        sessionStorage.removeItem('MLP_Plan_Details');
        sessionStorage.removeItem('MLP_PopularPlan');
      }
    }
    const utm_source = params.get('utm_source')?.toUpperCase();
    const ndclid = params.get('ndclid');
    if (utm_source && utm_source === 'NEX' && ndclid) {
      setToStorage('barCodeId', `NEXT${utm_source}`);
      setToStorage('ndclid', ndclid);
    }
    let twsFlow = getTwsFlow() ? getTwsFlow() : params.get('type') ? params.get('type') : null;
    const comboflow = params.get('comboflow');
    const isSeqFwaEmailflow = params.get('isFWAEmailOrderingFlow');
    const testcombo = params.get('testcombo');
    const comboEmailFlow = params.get('isComboEmailflow');
    const hasRequiredParams = params.has('restricted') && params.has('sedOfferId') && params.has('restrictedProductType');
    if (hasRequiredParams) {
      const restricted = params.get('restricted');
      const sedOfferId = params.get('sedOfferId');
      const restrictedProductType = params.get('restrictedProductType');
      if ((restricted === 'true' || restricted === true) && restrictedProductType === 'SPO') {
        const salesLandingOffers = {
          sedOfferId,
          restrictedProductType,
          restricted,
        };
        window.sessionStorage.setItem('salesLandingOffers', JSON.stringify(salesLandingOffers));
      } else {
        window.sessionStorage.removeItem('salesLandingOffers');
      }
    }

    if (!window.localStorage.getItem('lqwidgetloadded')) {
      logMetrics('lqwidget', 'lqwidgetloadded', 'Info');
      window.localStorage.setItem('lqwidgetloadded', 'Y');
    }
    const sessionRetry = params.get('session_retry') === 'Y';
    if (sessionRetry || (!isLoggedIn && this.props?.sessionTimeout?.sessionExpired)) {
      this.props.dispatch(resetSessionView());
      this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
    }
    window.sessionStorage.setItem('GeoQualBlock', 'N');
    window.sessionStorage.removeItem('ResponsiveLQBulkRes');
    this.props.dispatch(setStreetError(false));
    this.props.dispatch(setStreetGeoError(false));
    this.props.dispatch(invalidAddressError(false));
    let platForm = getLQPlatform();
    window.sessionStorage.removeItem('sessionIdFromPdm');
    const renderSetting = getRenderSettings();
    if (this.isTwsFilterdFlow) {
      platForm = 'FIOS';
    }
    if (platForm) {
      this.props.dispatch(Action.updatePlatForm(platForm));
    }
    let flowType = getFlowType();
    // const srcInfo = getSourceInfo();
    if (
      flowType === '' &&
      window.location.search &&
      (window.location.search.toLowerCase().indexOf('move=y') > -1 || window.location.search.toLowerCase().indexOf('type=moving') > -1)
    ) {
      flowType = Flowtype.MOVERS;
    }
    if (srcInfo && (srcInfo === 'retail-qr-code' || srcInfo === 'store_locator' || srcInfo === 'MOVERS')) {
      flowType = srcInfo;
    }
    this.props.dispatch(Action.setFlowType(flowType));
    let server_custStoreID = '';
    let server_visitorId = '';
    let server_digitalIgSession = '';
    if (!(isMYVZWAPP() && this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC')) {
      closeModalWindow(this.props.dispatch);
    }
    const widgetHost = getWidgetHost(window.location.pathname);
    if (widgetHost) {
      this.props.dispatch(Action.updateWidgetHost(widgetHost));
    }
    // If user is logged in and role as guest, Considering isLoggedIn as false for Sequential flow (Mobility Email to FWA 5G).
    // const isLoggedIn =
    //   srcInfo && srcInfo === 'digitalCombo' && getCookie('role') === 'guest'
    //     ? false
    //     : checkLoggedInStatus(this.props.dispatch, this.props.loopQualApp);
    // const isLoggedInUserInfo = checkLoggedInStatusUserInfo(this.props.dispatch);
    // const isFiosVzt = checkFiosVzt(this.props.dispatch);
    this.keydownOrMousedownCheck();
    Action.getLoopQualAppStaticContent(this.props.dispatch);
    const vtoken = params.get('vtoken');
    // const autogeoqual = params.get('autogeoqual');
    if ((isLoggedIn || isLoggedInUserInfo) && (getSourceInfo() === 'HomeLQ' || params.get('smlpLQ') === 'Y')) {
      const redirectUrl = prodEspanolDomain() ? '' : getDomainUrl();
      const pageURL = isLoggedInUserInfo ? Pages.redirectToFios : Pages.redirectToFiveGHome;
      redirectToPlansPage(`${redirectUrl + pageURL}`, '_blank');
    }
    const cVal = params.get('c');
    const abrVal = params.get('abr');
    const skipregQS = params.get('skipReg');
    const isACPFlow = getSourceInfo() === 'ACP' || params.get('type') === 'acp';
    const isVfEmail = params.get('CMP')?.indexOf('vfqualfwa') > -1 || params.get('CMP')?.indexOf('vfecqualfw') > -1;
    const isVfResume = params.get('resume') === 'y';
    const vfMnhFlow = params.get('vfmnh') === 'y';
    const moversPh2 = flowType === Flowtype.MOVERS && params.get('ph2') === 'y';
    const moversFromDisconnect = flowType === Flowtype.MOVERS && params.get('fromDisconnect') === 'y';
    const moversPh2Titan = params.get('ph2Titan') === 'y';
    const disableMoversPh2 = params.get('disableMoversPh2') === 'y';
    const doLqCall = params.get('doLqCall');
    const sourceInfo = params.get('sourceInfo') ? params.get('sourceInfo') : null;
    const crossSell = getCrossSell();
    const mhToken = params.get('vzt') ? params.get('vzt') : null;
    const clearAdd = params.get('editfromOrdering') ? params.get('editfromOrdering') : '';
    const lqResponseStorage = window.sessionStorage.getItem('LQResponse');
    const locusFetch = params.get('locusid') ? params.get('locusid') : null;
    const isWifiBackUpFromFios = !!(params.get('wifibackupfios') && (params.get('locusID') || (params.get('ntas_id') && params.get('state'))));
    const isFccFlow = getSourceInfo() === 'FCC' || params.get('type') === 'fcc';
    const teachersFlow = this.twsFilterFlow(twsSrcInfo, twsFlow) === 'teacher';
    const isComboMFJT = getSourceInfo() === 'digitalComboMFJT' || params.get('type') === 'digitalComboMFJT';
    const isECComboMFJT = getSourceInfo() === 'EC_digitalComboMFJT' || params.get('type') === 'EC_digitalComboMFJT';
    if (isECComboMFJT || isComboMFJT) {
      closeModalWindow(this.props.dispatch);
      logMetrics('combo', 'Page load closeModalWindow', 'Info');
    }
    const isNycPh2 = getFiosInquire() === 'Y' || params.get('type') === 'fiosInquire';
    const newConnection = params.get('PN')
      ? params.get('PN')
      : window.sessionStorage.getItem('couponToken')
      ? window.sessionStorage.getItem('couponToken')
      : '';
    const digiCombo = params.get('digitalCombo') ? params.get('digitalCombo') : '';
    const session_visitor_id = window.sessionStorage.getItem('visitor_id');
    const isFromPLansLq = params.get('fromPlansLq') === 'Y';
    let autoGeoQualCriteriaSatisfied = false;
    if (
      renderSetting?.miniLq?.enabled &&
      !this.props.addressQualification.lqsTagTriggered &&
      this.props.customerStreetDetails === null &&
      !lqResponseStorage &&
      !isLoggedIn &&
      !clearAdd &&
      !this.plansvisit
    ) {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
          if (PermissionStatus.state === 'granted') {
            autoGeoQualCriteriaSatisfied = true;
            logMetrics('geo', 'Granted already', 'Info');
          }
        });
      }
    }
    if (isLoggedIn && !clearAdd && !this.plansvisit) {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
          if (PermissionStatus.state === 'granted') {
            logMetrics('geo', 'Granted access already in LoggedIn', 'Info');
          }
        });
      }
    }
    if (clearAdd || this.plansvisit) {
      Action.clearDetails(this.props.dispatch, this.props.cookies, this.props.fiosDetails, this.props.loopQualApp);
      linkVzTag('change address -plans');
    }
    if (isLoggedInUserInfo) {
      const session_visit_id = window.sessionStorage.getItem('visit_id');
      if (!session_visit_id) {
        generateVisitId();
      }
    }
    const responsekey = await getRuntimekey(this.props.dispatch);
    const isLoggedInTWSTK = getLoggedInTwsLQ();
    Action.setNSAFlowParams(isLoggedIn);
    this.props.dispatch(resetBulkDetails());
    if (!session_visitor_id && srcInfo?.toUpperCase() !== 'CPC') {
      generateVisitorId();
    }
    this.setColorTheme('Y'); // setting monarch theme
    if (isACPFlow) {
      this.props.dispatch(Action.updateACP(isACPFlow));
      this.props.dispatch(Action.updateLq4(false));
      this.props.dispatch(Action.updateVfEc(true));
      if (isVfEmail) window.sessionStorage.setItem('isVfEmail', 'true');
      if (params.get('newExp') === 'Y' && params.get('CMP').indexOf('vfncqualfw') > -1) {
        window.sessionStorage.setItem('isVfEmailNC', 'true');
        this.props.dispatch(Action.updateVFEmailNc(true));
      } else {
        window.sessionStorage.setItem('isVfEmailNC', 'false');
        this.props.dispatch(Action.updateVFEmailNc(false));
      }
      if (isVfResume) this.props.dispatch(Action.updateVFResumeFlow(true));
      const session_visit_id = window.sessionStorage.getItem('visit_id');
      if (!session_visit_id) {
        generateVisitId();
      }
    } else if (
      (renderSetting && renderSetting.miniLq && renderSetting.miniLq.enabled && this.props.loopQualApp.flowType !== Flowtype.MOVERS) ||
      (teachersFlow && params.get('twsStickyLq') === 'Y') ||
      this.isResponsiveLQ
    ) {
      this.props.dispatch(Action.updateLq4(true));
      setToStorage('lq4', true);
      this.props.dispatch(Action.updateACP(false));
    } else {
      this.props.dispatch(Action.updateLq4(false));
      window.sessionStorage.removeItem('lq4');
      this.props.dispatch(Action.updateACP(false));
    }
    if (moversFromDisconnect) window.sessionStorage.setItem('fromDisconnect', true);
    if (
      moversPh2 ||
      (responsekey && responsekey.data && responsekey.data.EnableMoverPh2 && responsekey.data.EnableMoverPh2 === 'Y') ||
      window.sessionStorage.getItem('moversPh2')
    ) {
      this.props.dispatch(Action.updateMoversPh2(true));
      window.sessionStorage.setItem('moversPh2', true);
      const value = 'moversPh2=true;path=/';
      document.cookie = value;
      if (moversPh2Titan || responsekey.data.EnableMoverPh2Titan === 'Y' || window.sessionStorage.getItem('moversPh2Titan')) {
        this.props.dispatch(Action.updateMoversPh2Titan(true));
        window.sessionStorage.setItem('moversPh2Titan', true);
      } else {
        this.props.dispatch(Action.updateMoversPh2Titan(false));
      }
      if (
        disableMoversPh2 ||
        (responsekey.data.DisableMoversPh2 === 'Y' && params.get('disableMoversPh2') !== 'n') ||
        (window.sessionStorage.getItem('disableMoversPh2') && params.get('disableMoversPh2') !== 'n')
      ) {
        this.props.dispatch(Action.updateDisableMoversPh2(true));
        window.sessionStorage.setItem('disableMoversPh2', true);
      } else {
        this.props.dispatch(Action.updateDisableMoversPh2(false));
      }
    } else {
      this.props.dispatch(Action.updateMoversPh2(false));
      this.props.dispatch(Action.updateMoversPh2Titan(false));
    }
    if (newConnection && !clearAdd) {
      setToStorage('couponToken', newConnection);
      if (window.location.pathname.includes('inhome/checkavailability')) {
        this.updateCurrentView(LQ_VIEWS.COMMON_LQ);
      }
      this.props.dispatch(Action.updateNewConnection(true));
    } else {
      this.props.dispatch(Action.updateNewConnection(false));
    }
    if (mhToken) {
      this.props.dispatch(Action.updateMhToken(mhToken));
    } else {
      this.props.dispatch(Action.updateMhToken(null));
    }
    if (crossSell) {
      this.props.dispatch(Action.updateSourceInfo(crossSell));
    } else if (sourceInfo) {
      this.props.dispatch(Action.updateSourceInfo(sourceInfo));
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      this.props.dispatch(Action.updateSourceInfo(srcInfo || null));
    }
    if (locusFetch) {
      this.props.dispatch(Action.updateSourceInfo('external'));
    }
    if (isFccFlow) {
      this.props.dispatch(Action.updateHideMiniLq(true));
      this.props.dispatch(Action.updateFccFlow(true));
    } else if (this.isTwsFilterdFlow || this.isResponsiveLQ) {
      // if (this.isTwsFilterdFlow && !this.props.lqResult.lqAddress) {
      this.props.dispatch(Action.updateHideMiniLq(true));
      // }
    } else {
      this.props.dispatch(Action.updateHideMiniLq(false));
      this.props.dispatch(Action.updateFccFlow(false));
    }
    if (!getCookie('loggedIn') && !getCookie('userinfo')) {
      setTimeout(() => {
        this.props.dispatch(Action.updateSkeleton(false));
      }, 1000);
    } else {
      this.props.dispatch(Action.updateSkeleton(false));
    }

    if (this.isResponsiveLQ) {
      this.props.dispatch(Action.updateRespLQ(true));
    } else {
      this.props.dispatch(Action.updateRespLQ(false));
    }

    if (isFromPLansLq) {
      this.props.dispatch(Action.updateFromPlansLQ(true));
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      this.props.dispatch(Action.updateFromPlansLQ(false));
    }

    if ((srcInfo && srcInfo === 'digitalCombo') || digiCombo) {
      this.props.dispatch(Action.updateSourceInfo('digitalCombo'));
      this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
      this.props.dispatch(showLoader());
    }
    if (comboEmailFlow === 'true') {
      this.props.dispatch(Action.updateComboEmailFlow(true));
      openModalWindow('LQAPP', this.props.dispatch);
      this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
      this.props.dispatch(showLoader());
      openViewVzTag('Please hang tight while we gather some information');
      pageViewPageVzTag('combo checkavailability');
      comboLqint(
        this.props.dispatch,
        this.props.loopQualApp,
        this.props.fiosDetails,
        this.props.addressQualification,
        this.props.unitLookupDetails,
        this.props.moveDetails,
      );
    } else {
      this.props.dispatch(Action.updateComboEmailFlow(false));
    }
    if (isComboMFJT && !this.props.loopQualApp.isLoggedIn && !this.props.loopQualApp.isLoggedInUserInfo) {
      this.props.dispatch(Action.updateComboMFJT(true));
      this.props.dispatch(Action.updateHideMiniLq(true));
      this.props.dispatch(Action.updateLq4(false));
    } else {
      this.props.dispatch(Action.updateComboMFJT(false));
    }
    if (isECComboMFJT && (this.props.loopQualApp.isLoggedIn || this.props.loopQualApp.isLoggedInUserInfo)) {
      this.props.dispatch(Action.updateECComboMFJT(true));
      this.props.dispatch(Action.updateHideMiniLq(true));
      this.props.dispatch(Action.updateLq4(false));
    } else {
      this.props.dispatch(Action.updateECComboMFJT(false));
    }
    if (
      (twsFlow &&
        (twsFlow === 'medical' ||
          twsFlow === 'student' ||
          twsFlow === 'medical_new' ||
          twsFlow === 'responder' ||
          twsFlow === 'teacher' ||
          twsFlow === 'milvet' ||
          twsFlow === 'nurse')) ||
      (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1)
    ) {
      twsFlow = this.twsFilterFlow(twsSrcInfo, twsFlow);
      this.props.dispatch(Action.updateTWS(twsFlow));
      const session_visit_id = window.sessionStorage.getItem('visit_id');
      if (!session_visit_id) {
        generateVisitId();
      }
    } else {
      this.props.dispatch(Action.updateTWS(null));
    }
    if (this.props.loopQualApp.currentLQView === 'COMMON_LQ') {
      if (!twsFlow && !isACPFlow && !newConnection) {
        this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
      }
    }
    if (isNycPh2) {
      this.props.dispatch(Action.updateNycPh2(true));
    } else {
      this.props.dispatch(Action.updateNycPh2(false));
    }
    if (window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1 && srcInfo && srcInfo === 'digitalCombo') {
      this.props.dispatch(showLoader());
      orderNowFiveGNsa(
        this.props.addressQualification,
        this.props.loopQualApp,
        this.props.dispatch,
        true,
        this.props.moveDetails,
        false,
        this.props.customerStreetDetails,
        this.props.unitLookupDetails,
        this.props.fiosDetails,
        false,
        true,
        false,
        false,
        true,
      );
    }
    if (this.props.modalOverlay.existingCustomerModal) {
      existingCustomerModal(this.props.dispatch, false);
    }
    if (this.props.addressQualification.helpView) {
      updateHelpView(this.props.dispatch, false);
    }
    window.vzlqw = {};
    window.vzlqw.planLQFocus = false;
    window.vzlqw.dispatch = this.props.dispatch;
    window.vzlqw.closeModal = this.closeModal;
    window.vzlqw.isvzlqwModalOpened = false;
    if (!(window.location.search && window.location.search.toLowerCase().includes('?availability'))) {
      window.vzlqw.openModal = this.onCheckAvailabilityClick;
    }
    let checkAvailBtnTrack = 'check avail - nav';
    if (
      this.props.lqResult.qualifiedCBand ||
      this.props.lqResult.fiveGQualified ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW
    ) {
      if (this.props.lqResult.qualifiedCBand) {
        checkAvailBtnTrack = 'order now - 5g eligible -cband';
      } else {
        checkAvailBtnTrack = 'order now - 5g eligible -mmWave';
      }
    } else if (this.props.lqResult.lteQualified || this.props.loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW) {
      checkAvailBtnTrack = 'order now - lte eligible';
    } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW) {
      if (this.props.lqResult.qualifiedCBand) {
        checkAvailBtnTrack = 'order now - 5g eligible -cband';
      } else if (this.props.lqResult.fiveGQualified) {
        checkAvailBtnTrack = 'order now - 5g eligible -mmWave';
      } else if (this.props.lqResult.lteQualified) {
        checkAvailBtnTrack = 'order now - lte eligible';
      }
    } else if (
      this.props.lqResult.fiosQualified ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW
    ) {
      checkAvailBtnTrack = 'order now - fios eligible';
    } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
      checkAvailBtnTrack = 'not eligible';
    }
    Action.setCheckAvailBtnTrack(checkAvailBtnTrack, this.props.dispatch);
    if (this.retailQrCode) {
      this.props.dispatch(Action.setFlowType('retail-qr-code'));
    }
    setToStorage('isCBandFlow', 'Y');
    setToStorage('lqWidgetLoaded', true);
    setToStorage('isWillow', true);
    setToStorage('skipReg', skipregQS === 'Y' ? 'Y' : 'N');
    setToStorage('enableFuncComp', params.get('chkout') === 'Y' ? 'Y' : 'N');
    setToStorage('enableACP', true);
    setToStorage('LqThrottle', getCookie('cdlThrottleList') ? getCookie('cdlThrottleList') : '');
    window.vzlqw.lqWidgetLoaded = true;
    if (window.location.pathname.includes('cpcCheckAvailability')) {
      const bridgeAPIMsg = {
        actionType: 'updateNavigation', // Bridge API Tittle cpcCheckAvailability changes
        navigationBar: {
          moleculeName: 'navigationBar',
          title: 'Home Internet',
          alwaysShowBackButton: true, // false if u  want to hide the back
        },
      };
      this.mvaBridgeApiCall(bridgeAPIMsg);
    } else if (
      window.location.pathname.includes('inhome/checkavailability') ||
      window.location.pathname.includes('inhome/qualification') ||
      window.location.pathname.includes('sales/home/lq') ||
      window.location.pathname.includes('inhome/moves/checkavailability') ||
      window.location.pathname.includes('inhome/moves/qualification')
    ) {
      const coupon = params.get('PN');
      const lq4 = params.get('lq4');
      const couponPattern = /^[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}$/;
      const newCouponPattern = /^DAW01.{10,16}$/;
      const isCouponInvoked = window.sessionStorage.getItem('couponInvoked') ? window.sessionStorage.getItem('couponInvoked') : false;
      const validToken = coupon ? couponPattern.test(coupon.toUpperCase()) || newCouponPattern.test(coupon.toUpperCase()) : false;
      if (coupon && validToken && !isCouponInvoked) {
        this.props.dispatch(showLoader());
        setTimeout(() => {
          validateToken(coupon, this.props.dispatch);
        }, 1000);
        openModalWindow('LQAPP', this.props.dispatch);
        this.invokeInitTag(false, 'checkAvaillqInitCare', 'lq check avail - care', true, this.props.loopQualApp, this.props.customerStreetDetails);
      } else if (
        isCouponInvoked &&
        window.location.search &&
        window.location.search.toLowerCase().indexOf('testfwa=y') > -1 &&
        (window.location.host.includes('ebiz.verizon.com') || window.location.host.includes('98.verizon.com'))
      ) {
        window.location.href = `https://vzwqa3.verizonwireless.com/sales/home/external/checkAvailability.html?PN=${coupon}`;
      } else {
        if (lq4) {
          closeModalWindow(this.props.dispatch);
        } else {
          openModalWindow('LQAPP', this.props.dispatch);
        }
        if (vtoken || (abrVal && cVal)) {
          setTimeout(() => {
            getVendorDetails(this.props.dispatch);
          }, 1000);
        }
        if (this.props.loopQualApp.TWS || isACPFlow) {
          const currentView =
            isACPFlow &&
            this.props.loopQualApp.isLoggedIn &&
            (this.props.addressQualification?.lqResult?.existingFWA ||
              window.sessionStorage.getItem('existingFwa') === 'Y' ||
              this.props.bulkQualDetails.preferredbulkServiceDetail?.existingFWA) &&
            this.props?.loopQualApp?.isVfEc &&
            window.sessionStorage.getItem('isVfExist') === 'false'
              ? window.sessionStorage.getItem('isVfEmail') === 'true'
                ? LQ_VIEWS.VF_QUAL_FWA
                : LQ_VIEWS.VF_QUESTIONAIRE
              : window.sessionStorage.getItem('isVfEmailNC') === 'true'
              ? LQ_VIEWS.ADDRESS_QUALIFICATION
              : LQ_VIEWS.COMMON_LQ;
          this.updateCurrentView(currentView);
        }
        this.invokeInitTag(
          !!lqResponseStorage,
          'checkAvaillqInitFullPage',
          'lq check avail - landing',
          true,
          this.props.loopQualApp,
          this.props.customerStreetDetails,
        );
      }
      if (lq4) {
        closeModalWindow(this.props.dispatch);
      }
    } else if (vtoken) {
      await validateVendorToken(vtoken, this.props.dispatch);
    } else if (abrVal && cVal) {
      const queryDetails = { abrVal, c: cVal, cmpId: params.get('CMP'), locationCode: '' };
      validateVendor(queryDetails, this.props.dispatch);
    }
    if (window.location.search && window.location.search.toLowerCase().indexOf('?availability') > -1) {
      if (this.props.loopQualApp.lq4 && !this.props.addressQualification.lqsTagTriggered) {
        inVokeLqCheckNetworkAvailability(
          'stickyBar-CTA',
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.addressQualification,
          this.props.loopQualApp,
          this.props.dispatch,
          this.props.cookies,
          '',
          this.props.fiosDetails,
          this.props.moveDetails,
          null,
          this.props.parserAttempt,
          false,
        );
        greyOut();
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        this.invokeInitTag(!!lqResponseStorage, 'checkAvaillqInit', 'lq check avail', false, this.props.loopQualApp);
      }
    }
    this.initExternalTriggers();
    if (document.readyState === 'complete') {
      this.initExternalTriggers();
      this.initExternalSignInTriggers();
    } else if (window.addEventListener) {
      window.addEventListener('load', () => {
        this.initExternalTriggers();
        this.initExternalSignInTriggers();
      });
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', () => {
        this.initExternalTriggers();
        this.initExternalSignInTriggers();
      });
    }
    this.isVendorFlowParams();
    if (this.isLqsCompleted) {
      if (this.props.loopQualApp.isNSAFlow) {
        if (window.location.host.includes('qa') || window.location.host.includes('localhost')) {
          server_visitorId = window.localStorage.getItem('visitor_id');
        }
      }
      const response = await getCookieVisitIdAndVisitorIdNoParam();
      server_custStoreID = response && response.data && response.data.server_cust_Store_pid ? response.data.server_cust_Store_pid : '';
      server_visitorId = response && response.data && response.data.server_visitor_id ? response.data.server_visitor_id : '';
      server_digitalIgSession = response && response.data && response.data.server_digital_ig_session ? response.data.server_digital_ig_session : '';
      window.localStorage.setItem('visitor_id', server_visitorId);
      const globalId = getCookie('GLOBALID') ? getCookie('GLOBALID') : '';
      if (globalId && !lqResponseStorage) {
        getPdm(this.props.dispatch);
      }
    }
    if (
      isLoggedIn &&
      this.props.loopQualApp.roleType &&
      (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
    ) {
      return true;
    }
    if (locusFetch || isWifiBackUpFromFios) {
      const locusId = locusFetch || (isWifiBackUpFromFios ? params.get('locusID') : '');
      locusIDfetch(
        locusId,
        this.props.dispatch,
        this.props.loopQualApp,
        this.props.fiosDetails,
        this.props.addressQualification,
        this.props.unitLookupDetails,
        this.props.moveDetails,
        this.props.cookies,
      );
    } else if (srcInfo === 'digitalCombo' || digiCombo || isSeqFwaEmailflow === 'true' || comboflow === 'fios' || testcombo === 'y') {
      pageViewPageVzTag('combo checkavailability');
      if (isSeqFwaEmailflow === 'true') {
        this.props.dispatch(Action.updateSeqFwaEmailFlow(true));
        openModalWindow('LQAPP', this.props.dispatch);
        this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
        this.props.dispatch(showLoader());
        openViewVzTag('Please hang tight while we gather some information');
      } else {
        this.props.dispatch(Action.updateSeqFwaEmailFlow(false));
      }
      comboLqint(
        this.props.dispatch,
        this.props.loopQualApp,
        this.props.fiosDetails,
        this.props.addressQualification,
        this.props.unitLookupDetails,
        this.props.moveDetails,
      );
    } else if (isACPFlow && (isLoggedInUserInfo || isLoggedIn || vfMnhFlow)) {
      const bulkPlanVisited = !!window.sessionStorage.getItem('bulkPlanVisited');
      if (window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1) {
        openModalWindow('LQAPP', this.props.dispatch);
      }
      if (vfMnhFlow) {
        this.updateCurrentView(LQ_VIEWS.VF_MNH_QUALIFIED_VIEW);
        checkLoggedInBulkQual_ReFetch(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      } else if (isLoggedIn) {
        if (bulkPlanVisited) {
          checkLoggedIn(this.props.dispatch, this.props.cookies, this.props.loopQualApp, '', this.props.addressQualification, this.props.moveDetails);
        } else {
          this.props.dispatch(Action.updateIsBulkQual(true));
          checkLoggedInBulkQual(
            this.props.dispatch,
            this.props.loopQualApp,
            this.props.fiosDetails,
            this.props.addressQualification,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.moveDetails,
            this.props.cookies,
            false,
            this.props.bulkQualDetails,
          );
        }
      } else if (isLoggedInUserInfo) {
        Action.fetchFiosProfile(this.props.dispatch, this.props.loopQualApp);
      }
    } else if (isLoggedInTWSTK) {
      const isAuthY = window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1;
      if (this.props.loopQualApp.isLoggedInUserInfo && isAuthY) {
        sendDataVzTagMessage('TWS FIOS LoggedIn customer', '');
        const signedURLTWS = getInHomeTWSURL(this.props.loopQualApp);
        console.log('signedURLTWS', signedURLTWS);
        window.location.href = signedURLTWS;
        return true;
      }
      if (isLoggedIn) {
        if (window.location.search && isAuthY) {
          openModalWindow('LQAPP', this.props.dispatch);
        }
        this.props.dispatch(Action.updateIsBulkQual(true));
        checkLoggedInBulkQual(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      }
    } else if (newConnection) {
      fetchAuthDetailsDAW(newConnection, isLoggedInUserInfo, isLoggedIn, this.props.loopQualApp);
    } else if (this.props.loopQualApp.flowType === Flowtype.MOVERS && (isLoggedInUserInfo || isLoggedIn)) {
      if (window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1) {
        openModalWindow('LQAPP', this.props.dispatch);
      }
      if (isLoggedIn) {
        checkLoggedInMV(this.props.dispatch);
      }
    } else if (
      isLoggedIn &&
      this.props.loopQualApp.sourceInfo?.toUpperCase() !== 'CPC' &&
      !clearAdd &&
      !this.isResponsiveLQ &&
      !this.plansvisit &&
      this.props.loopQualApp.sourceInfo?.toUpperCase() !== 'AALCOMBO' &&
      !isECComboMFJT &&
      !isFiosVzt
    ) {
      const bulkPlanVisited = !!window.sessionStorage.getItem('bulkPlanVisited');
      if (this.props.loopQualApp.flowType === Flowtype.storeLocater || bulkPlanVisited || isFromPLansLq) {
        checkLoggedIn(this.props.dispatch, this.props.cookies, this.props.loopQualApp, '', this.props.addressQualification, this.props.moveDetails);
      } else if (wifiBackupFromFios) {
        const selectedAddressString = wifiBackupFromFiosAddress();
        if (selectedAddressString) {
          const selectedAddress = JSON.parse(selectedAddressString);
          checkNetworkAvailability(
            selectedAddress,
            this.props.unitLookupDetails,
            this.props.addressQualification,
            this.props.loopQualApp,
            this.props.dispatch,
            null,
            '',
            this.props.fiosDetails,
            this.props.moveDetails,
          );
          openModalWindow('LQAPP', this.props.dispatch);
        }
      } else {
        this.props.dispatch(Action.updateIsBulkQual(true));
        setToStorage('FWALqAALThrottle', 'BULK');
        checkLoggedInBulkQual(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      }
    } else if (
      isLoggedInUserInfo &&
      !isLoggedIn &&
      this.props.loopQualApp.flowType !== Flowtype.MOVERS &&
      !this.props.loopQualApp.isACPFlow &&
      !this.props.loopQualApp.TWS &&
      !lqResponseStorage
    ) {
      Action.fetchFiosProfile(this.props.dispatch, this.props.loopQualApp, true); // VZWHDE-1179
    } else if (autoGeoQualCriteriaSatisfied) {
      autoLocationPopulate(
        this.props.unitLookupDetails,
        this.props.addressQualification,
        this.props.loopQualApp,
        this.props.dispatch,
        this.props.cookies,
        this.props.fiosDetails,
        this.props.moveDetails,
      );
    } else if (
      (this.props.loopQualApp.currentLQView !== LQ_VIEWS.UN_QUALIFIED_VIEW &&
        this.props.loopQualApp.currentLQView !== LQ_VIEWS.BUSINESSADDRESS_VIEW) ||
      this.props.lqResult.fiosQualified ||
      this.props.lqResult.fiveGQualified ||
      this.props.lqResult.qualifiedCBand ||
      this.props.lqResult.lteQualified ||
      (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW && !lqResponseStorage)
    ) {
      if (doLqCall === 'Y' || wifiBackupFromFios) {
        let selectedAddressString = '';
        if (wifiBackupFromFios) {
          selectedAddressString = wifiBackupFromFiosAddress();
        } else {
          selectedAddressString = window.sessionStorage.getItem('selectedAddress');
        }
        if (selectedAddressString) {
          const selectedAddress = JSON.parse(selectedAddressString);
          checkNetworkAvailability(
            selectedAddress,
            this.props.unitLookupDetails,
            this.props.addressQualification,
            this.props.loopQualApp,
            this.props.dispatch,
            null,
            '',
            this.props.fiosDetails,
            this.props.moveDetails,
          );
          openModalWindow('LQAPP', this.props.dispatch);
        }
      } else if (
        this.props.addressQualification.lqsTagTriggered ||
        (this.props.addressQualification.lqResult &&
          (this.props.addressQualification.lqResult.fiosQualified ||
            this.props.addressQualification.lqResult.fiveGQualified ||
            this.props.addressQualification.lqResult.qualifiedCBand ||
            this.props.addressQualification.lqResult.lteQualified)) ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW
      ) {
        const customerDetailPresent = !!this.props.customerStreetDetails;
        const addressInfoPresent = !!(
          this.props.lqResult &&
          this.props.lqResult.addrInfo &&
          this.props.lqResult.addrInfo.state &&
          this.props.lqResult.addrInfo.zip &&
          this.props.lqResult.addrInfo.addressLine1 &&
          this.props.lqResult.addrInfo.city
        );
        if (!customerDetailPresent && addressInfoPresent) {
          logMetrics('Revisit lqsTagTriggered by addrInfo', `${JSON.stringify(this.props.lqResult.addrInfo)}`, 'Info');
          this.props.dispatch(onStreetSelection(this.props.lqResult.addrInfo));
        }
        if (!this.isResponsiveLQ && (this.isLqsCompleted || window.location.host.includes('qa')) && (customerDetailPresent || addressInfoPresent)) {
          const isResumeCase =
            getFromStorage('cartResponse') &&
            getFromStorage('ExistingCase') &&
            this.props?.loopQualApp?.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW;
          let addressResults = null;
          let unitInfo = null;

          if (!isResumeCase) {
            addressResults = await getAddressInfoAgain(this.props.customerStreetDetails, this.props.lqResult.addrInfo, this.props.dispatch);
            if (!Array.isArray(addressResults) && addressResults?.isUnit) {
              unitInfo = { customerUnitDetails: addressResults?.unitInfo };
              addressResults = null;
            }
            if (!customerDetailPresent && addressInfoPresent && addressResults?.[0]) {
              this.props.dispatch(onStreetSelection(addressResults?.[0]));
            }
          }
          setTimeout(() => {
            if (vtoken) {
              setTimeout(() => {
                checkNetworkAvailability(
                  customerDetailPresent ? this.props.customerStreetDetails : this.props.lqResult.addrInfo,
                  this.props.unitLookupDetails,
                  this.props.addressQualification,
                  this.props.loopQualApp,
                  this.props.dispatch,
                  this.props.cookies,
                  '',
                  this.props.fiosDetails,
                  this.props.moveDetails,
                  null,
                  true,
                );
              }, 750);
            } else if (!isResumeCase && !(isLoggedIn && (isECComboMFJT || srcInfo?.toUpperCase() === 'CPC'))) {
              checkNetworkAvailability(
                addressResults?.[0] || (customerDetailPresent ? this.props.customerStreetDetails : this.props.lqResult.addrInfo),
                unitInfo || this.props.unitLookupDetails,
                this.props.addressQualification,
                this.props.loopQualApp,
                this.props.dispatch,
                this.props.cookies,
                '',
                this.props.fiosDetails,
                this.props.moveDetails,
                null,
                true,
              );
            }
          }, 2000);
        } else {
          const checkLocalStorage =
            window.localStorage !== null &&
            window.localStorage.getItem !== null &&
            window.localStorage.getItem('visitor_id') === null &&
            window.localStorage.getItem('persist:loop-qual-app-root') !== null;
          if (checkLocalStorage && !(server_custStoreID || server_visitorId || server_digitalIgSession)) {
            Action.clearDetails(this.props.dispatch, this.props.cookies, this.props.fiosDetails, this.props.loopQualApp);
          }
        }
      } else if (
        !isLoggedIn &&
        !this.props.addressQualification.lqsTagTriggered &&
        this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW
      ) {
        this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
      }
    }
    if (responsekey?.data?.EnableCaptcha === 'Y') {
      Action.reloadReCaptchaDetails(this.props.dispatch);
      this.props.dispatch(Action.resetRecaptchaResponse());
    }
    if (responsekey?.data?.PrefetchHome === 'Y') {
      PrefetchHomeInternet();
    }
    /* if (responsekey?.data?.EnablePrefetchFios === 'Y' || params.get('EnablePrefetchFios') === 'Y') {
      PrefetchFiosOrderingCriticalAssets();
    }
    if (params.get('EnablePrefetchFios') === 'Y') {
      PrefetchFiosOrdering();
    } */
    if (srcInfo !== 'store_locator') {
      prefetchHereMapScript();
    }
  }

  componentDidUpdate() {
    const streetInputText = document.getElementById('streetInputText');
    if (streetInputText) {
      console.log(streetInputText);
      console.log(this.props.unitInputError);
      if (this.props.unitInputError) {
        streetInputText.classList.add('unitErrorStreet');
      } else {
        streetInputText.classList.remove('unitErrorStreet');
      }
    }
  }

  twsFilterFlow = (twsSrcInfo, twsText) => {
    let twsFlow = twsText;
    if (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1) {
      twsFlow = twsSrcInfo.replace('tws_', '');
    }
    if (twsFlow === 'medical') {
      twsFlow = 'medical_new';
    }
    return twsFlow;
  };

  mvaBridgeApiCall = (bridgeAPIMsg) => {
    // Bridge API cpcCheckAvailability changes
    try {
      const bridgeAPIPostJson = JSON.stringify(bridgeAPIMsg);
      console.debug('bridgeapiPostJson---->', bridgeAPIPostJson);
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.mfAppInterface &&
        window.webkit.messageHandlers.mfAppInterface.postMessage
      ) {
        window.webkit.messageHandlers.mfAppInterface.postMessage(bridgeAPIPostJson);
        console.debug('bridgeapiIOS Call Successful---->', bridgeAPIPostJson);
      }
      if (window.mfAppInterface && window.mfAppInterface.postMessage) {
        window.mfAppInterface.postMessage(bridgeAPIPostJson);
        console.debug('bridgeapiAndriod Call Successful---->', bridgeAPIPostJson);
      }
    } catch (err) {
      console.error('bridgeAPIPostMsg Error: ', err);
    }
  };

  initExternalTriggers = () => {
    console.log('initExternalTriggers invoked', this.props);
    const { externalTrigger = [] } = this.props;
    const addClickListener = (elements) => {
      elements.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.stopImmediatePropagation();
          this.onCheckAvailabilityClick(e, true);
        });
      });
    };

    externalTrigger.forEach((elementSel) => {
      let extElement = [];
      extElement = elementSel && document.querySelectorAll(elementSel);
      if (extElement?.length > 0) {
        addClickListener(extElement);
      } else {
        extElement = elementSel && document.getElementsByClassName(elementSel.substring(1));
        if (extElement?.length > 0) {
          addClickListener(extElement);
        }
      }
    });
  };

  onClickSignInTrigger = () => {
    console.log('clicking button elems');
    if (this.props.loopQualApp.isLoggedInUserInfo && this.props?.loopQualApp?.TWS) {
      sendDataVzTagMessage('TWS FIOS LoggedIn customer', '');
      const signedURLTWS = getInHomeTWSURL(this.props.loopQualApp);
      console.log('signedURLTWS', signedURLTWS);
      window.location.href = signedURLTWS;
      return true;
    }
    if (this.props.loopQualApp.isLoggedIn) {
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      openModalWindow('LQAPP', this.props.dispatch);
      existingCustomerModal(this.props.dispatch, true);
    }
  };

  initExternalSignInTriggers = () => {
    console.log('initExternalsigninTriggers invoked', this.props);
    const { externalSignInTrigger = [] } = this.props;
    window.vzlqw.loadExternalSigninModal = this.onClickSignInTrigger;
    externalSignInTrigger.forEach((elementSel) => {
      let extElement = [];
      extElement = elementSel && document.querySelectorAll(elementSel);
      console.log('elems', extElement);
      if (extElement.length > 0) {
        extElement.forEach((el) => el.addEventListener('click', this.onClickSignInTrigger));
      }
    });
  };

  updateCurrentView = (targetView) => {
    // eslint-disable-next-line no-prototype-builtins
    if (targetView && LQ_VIEWS.hasOwnProperty(targetView)) {
      this.props.dispatch(Action.updateCurrentView(targetView));
    }
  };

  keydownOrMousedownCheck = () => {
    window.document.body.classList.add('hide-focus-outline'); // initially hide focus outline
    window.document.onkeydown = (e) => {
      const bodyClassList = window.document.body.classList; // enable focus outline when tabbing
      if (bodyClassList.contains('hide-focus-outline') && (e.keyCode === 9 || e.keyCode === 13)) {
        bodyClassList.remove('hide-focus-outline');
      }
    };
    window.document.onmousedown = () => {
      const bodyClassList = window.document.body.classList; // disable focus outline on mouse click
      if (!bodyClassList.contains('hide-focus-outline')) {
        bodyClassList.add('hide-focus-outline');
      }
    };
  };

  renderContent = () => {
    let content = stickyBannerContent.default;
    if (this.props.loopQualApp.flowType === Flowtype.MOVERS) {
      content = stickyBannerContent.default;
    } else if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW) {
      content = stickyBannerContent.default_bulk;
    } else if (this.props.loopQualApp.widgetHost === '5g') {
      content = stickyBannerContent.default_5g;
    } else if (this.props.loopQualApp.widgetHost === 'lte') {
      content = stickyBannerContent.default_lte;
    } else if (this.props.loopQualApp.widgetHost === 'fios' || this.props.loopQualApp.lqPlatform === Platforms.FIOS) {
      content = stickyBannerContent.default_fios;
    }
    return content;
  };

  getAddress = () => {
    const { lqResult, customerStreetDetails, revisitAddress } = this.props;
    return lqResult.lqAddress && lqResult.lqAddress.length > 4
      ? lqResult.lqAddress
      : customerStreetDetails && customerStreetDetails.disprawstr
      ? customerStreetDetails.disprawstr
      : revisitAddress;
  };

  invokeInitTag = (isOrderNow, initType, detailPageName, inhomeOpenView = false, loopQualApp, customerStreetDetails) => {
    setTimeout(() => {
      let modifiedDetailPageName = detailPageName;
      if (!inhomeOpenView) {
        if (isOrderNow && loopQualApp && loopQualApp.checkAvailBtnTrack !== 'check avail - nav') {
          // eslint-disable-next-line no-param-reassign
          modifiedDetailPageName = loopQualApp.checkAvailBtnTrack;
          if (modifiedDetailPageName === 'not eligible') {
            modifiedDetailPageName = 'lq result - noteligible';
          }
        }
        if (loopQualApp && loopQualApp.currentLQView === LQ_VIEWS.COMMON_LQ) {
          modifiedDetailPageName = 'Get Verizon Home Services';
        }
        openViewVzTag(modifiedDetailPageName);
      } else {
        getVZDLInit(initType, { lqInfo: loopQualApp, addressInfo: customerStreetDetails || '' }, isOrderNow);
      }
    }, 2000);
  };

  isVendorFlowParams = () => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search.toLowerCase());
      const vendorcVal = params.get('c');
      const vendorabrVal = params.get('abr');
      const vendorOutletId = params.get('outletid');
      const vendorLocationCode = params.get('locationcode');
      if (vendorOutletId && vendorLocationCode) {
        setToStorage('vendorOutletId', vendorOutletId);
        setToStorage('vendorLocationCode', vendorLocationCode);
      }
      if (vendorabrVal && vendorcVal) {
        setToStorage('vendorabrVal', vendorabrVal);
        setToStorage('vendorid', vendorcVal);
      }
      if ((vendorabrVal && vendorcVal) || (vendorOutletId && vendorLocationCode)) {
        const vendordetails = {
          vendorId: vendorcVal,
          locationCode: vendorLocationCode,
          outletid: vendorOutletId,
          abrval: vendorabrVal,
        };
        updateVendorDetails(vendordetails, this.props.dispatch);
      } else {
        updateVendorDetails('', this.props.dispatch);
      }
    } else {
      updateVendorDetails('', this.props.dispatch);
    }
  };

  setColorTheme = (monarchEnabled) => {
    const colors = configLQTheme(monarchEnabled);
    this.setState({ colors });
  };

  renderView = () => {
    console.log('state', this.state);
    const {
      mode,
      miniLq: miniLqConfig,
      loopQualApp,
      lqResult,
      multiServiceDetails,
      addressQualification,
      modalOverlay,
      addressLookup,
      sessionTimeout,
    } = this.props;
    const { colors } = this.state;
    const displayContent = this.renderContent();
    const lqAddress = this.getAddress() || '';
    const currentBaseView = getCurrentBaseView(loopQualApp.sessionTimeoutView || loopQualApp.currentLQView);
    const hideFccMiniLq = this.props && this.props.loopQualApp && this.props.loopQualApp.hideFccMiniLq;
    const params1 = new URLSearchParams(window.location.search);
    const checkBulkLoadParameter = params1.get('bulkEXPLoader') === 'Y';
    const isSkeletonView = loopQualApp?.showSkeleton && !this.isResponsiveLQ;
    let modalContent = (
      <>
        {currentBaseView}
        <Loader active={this.props.loaderStatus || isSkeletonView} />
      </>
    );
    if (getHomeLQSettings()) {
      return (
        <React.Suspense fallback={<div />}>
          <HomeLQMain onClick={this.onCheckAvailabilityClick}>{currentBaseView}</HomeLQMain>
        </React.Suspense>
      );
    }
    if (modalOverlay.existingCustomerModal) {
      modalContent = (
        <SignIn
          sessionTimeout={sessionTimeout}
          loopQualApp={loopQualApp}
          lqResult={lqResult}
          couponLQ={loopQualApp.couponLQ}
          dispatch={this.props.dispatch}
        />
      );
    }
    if (mode === 'inline') {
      return (
        <>
          {currentBaseView}
          <Loader active={this.props.loaderStatus} />
        </>
      );
    }
    return (
      <>
        {this.props.loaderStatus && checkBulkLoadParameter && <Loader active={this.props.loaderStatus} />}
        {miniLqConfig.enabled && isSkeletonView && (
          <DomComponent>
            <MinLQSkeleton />
          </DomComponent>
        )}
        {miniLqConfig.enabled && !hideFccMiniLq && !isSkeletonView && (
          <DomComponent>
            <MiniLq
              responsiveLQEmbeded={this.isResponsiveLQEmbeded}
              onClick={this.onCheckAvailabilityClick}
              displayContent={displayContent}
              miniLqConfig={miniLqConfig}
              lqAddress={lqAddress}
              lqResult={lqResult}
              widgetHost={loopQualApp.widgetHost}
              multiServiceDetails={multiServiceDetails}
              addressQualification={addressQualification}
              loopQualApp={loopQualApp}
              modalOverlay={modalOverlay}
              dispatch={this.props.dispatch}
              moveDetails={this.props.moveDetails}
              notify={this.props.notify}
              fiosDetails={this.props.fiosDetails}
              customerStreetDetails={this.props.customerStreetDetails}
              bulkQualDetails={this.props.bulkQualDetails}
              addressLookup={addressLookup}
              unitInputError={this.props.unitInputError}
              colors={colors}
            />
          </DomComponent>
        )}
        {modalOverlay.isModalOpened && modalOverlay.modalId === 'LQAPP' && (
          <ModalOverlay
            hideCloseBtn={this.props.loopQualApp.currentLQView === LQ_VIEWS.SESSION_TIMEOUT_VIEW && !this.props.modalOverlay.existingCustomerModal}
            modalBody={modalContent}
            modalOverlay={modalOverlay}
            dispatch={this.props.dispatch}
            loopQualApp={loopQualApp}
            sessionTimeout={this.props.sessionTimeout}
            addressQualification={addressQualification}
            moveDetails={this.props.moveDetails}
          />
        )}
      </>
    );
  };

  closeModal = () => {
    closeModalWindow(this.props.dispatch);
  };

  onCheckAvailabilityClick = (e, fromFedClick) => {
    const stickyMiniBtn = e?.target?.id === 'stickyBar-CTA';
    const isAddressQualification = this.props.loopQualApp?.currentLQView === LQ_VIEWS.ADDRESS_QUALIFICATION;
    if (fromFedClick) {
      const planType = e?.target?.getAttribute?.('data-type');
      const fwaType = ['5g', 'lte'];
      if (this.props.loopQualApp.widgetHost === 'fios') {
        const planId = e?.target?.getAttribute?.('data-productid');
        const planName = e?.target?.getAttribute?.('data-productname');
        const frameSession = { platform: 'fios', planId, planName };
        if (!planId || !planName) {
          sessionStorage.removeItem('MLP_Plan_Details');
        } else {
          logMetrics('LQ-Plan-PreSelection-FIOS', 'lq', 'Info');
          linkVzTag('LQ-Plan-PreSelection-FIOS', 'lq');
          setToStorage('MLP_Plan_Details', JSON.stringify(frameSession));
        }
      } else if (fwaType.indexOf(this.props.loopQualApp?.widgetHost) > -1) {
        const planName = e?.target?.getAttribute?.('data-productname') || e?.target?.ariaLabel?.split('Check availability for ')[1];
        const planId = e?.target?.getAttribute?.('data-productid');
        const frameSession = { platform: 'fwa', planName, planId };
        if (!planName) {
          sessionStorage.removeItem('MLP_Plan_Details');
        } else {
          logMetrics(`LQ-Plan-PreSelection-${this.props.loopQualApp.widgetHost}`, 'lq', 'Info');
          linkVzTag(`LQ-Plan-PreSelection-${this.props.loopQualApp.widgetHost}`, 'lq');
          setToStorage('MLP_Plan_Details', JSON.stringify(frameSession));
        }
      } else {
        sessionStorage.removeItem('MLP_Plan_Details');
        sessionStorage.removeItem('MLP_PopularPlan');
      }
      if (planType === 'popular') {
        logMetrics(`LQ-Popular-PreSelection-${this.props.loopQualApp.widgetHost}`, 'lq', 'Info');
        linkVzTag(`LQ-Popular-PreSelection-${this.props.loopQualApp.widgetHost}`, 'lq');
        setToStorage('MLP_PopularPlan', 'Y');
      } else {
        sessionStorage.removeItem('MLP_PopularPlan');
      }
    }
    if (this.props.sessionTimeout?.sessionExpired && !this.props.sessionTimeout?.loggedInExtendedView) {
      openModalWindow(this.props?.loopQualApp?.homeLQ ? 'HOMELQ' : 'LQAPP', this.props.dispatch);
      this.props.dispatch(hideLoader());
      return;
    }
    if (this.props.sessionTimeout?.loggedInExtendedView) {
      this.updateCurrentView(this.props.loopQualApp.currentLQView);
    }
    if (
      this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.roleType &&
      (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
    ) {
      this.updateCurrentView(LQ_VIEWS.LBO_VIEW);
      openModalWindow('LQAPP', this.props.dispatch);
      return true;
    }

    if (this.props?.loopQualApp?.isLoggedInUserInfo && this.props?.loopQualApp?.TWS) {
      sendDataVzTagMessage('TWS FIOS LoggedIn customer', '');
      const signedURLTWS = getInHomeTWSURL(this.props.loopQualApp);
      console.log('signedURLTWS', signedURLTWS);
      window.location.href = signedURLTWS;
      return true;
    }
    const el = document.getElementById('expand-contract');
    const bulkPlanVisited = !!window.sessionStorage.getItem('bulkPlanVisited');
    if (el && el.className === 'collapsed') {
      document.documentElement.scrollTop = 0;
      const mini = document.getElementsByClassName('minilq');
      if (mini && mini[0] && mini[0].style) {
        mini[0].style.inset = '';
        mini[0].style.top = '0px';
        mini[0].style.inset = '';
      }
      el.className = 'expanded';
      const streetInput = document.querySelector('#streetaddress');
      if (this.props.loopQualApp && !this.props.loopQualApp.lq4 && streetInput) {
        streetInput.focus();
      }
      greyOut();
      return true;
    }
    if (fromFedClick && this.props.loopQualApp?.homeLQ) {
      const streetInput = document.querySelector('#streetaddress');
      const isOrderNow = e.target?.textContent?.toLowerCase().includes('order now');
      const scenario = ['5G_HOME_QUALIFIED', 'LTE_HOME_QUALIFIED', '5G_CBAND_HOME_QUALIFIED'];
      const isFiosHappyFlow =
        this.props.addressLookup?.revisitAddressQualified === 'FIOS_QUALIFIED' && this.props.addressQualification?.lqResult?.fiosExtremeHappy;
      if (isOrderNow && (scenario.includes(this.props.addressLookup?.revisitAddressQualified) || isFiosHappyFlow)) {
        this.onCheckAvailabilityClick(e, false);
      } else if (
        isOrderNow &&
        this.props.addressQualification?.lqResult?.fiosQualification &&
        this.props.addressQualification?.lqResult?.fiosQualification?.inService === 'Y'
      ) {
        const inServiceElem = document.getElementById('inservice_btn');
        if (inServiceElem) {
          inServiceElem.click();
        }
      }
      window.scroll({ top: 0, behavior: 'smooth' });
      if (this.props.loopQualApp && streetInput) {
        streetInput.focus();
        setTimeout(() => {
          greyOut();
        }, 500);
      }
      return true;
    }
    const lqResponseStorage = window.sessionStorage.getItem('LQResponse');
    const params = new URLSearchParams(window.location.search);
    const { loopQualApp } = this.props;
    if (!checkTokenValidity()) {
      genarateToken();
    }
    if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC') {
      const session_visitor_id = window.sessionStorage.getItem('visitor_id');
      if (!session_visitor_id) {
        generateVisitorId();
      }
    }
    if (this.props.addressQualification.helpView) {
      updateHelpView(this.props.dispatch, false);
    }
    if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'AALCOMBO' || this.props.loopQualApp.isECComboMFJT) {
      this.props.dispatch(Action.updateIsBulkQual(true));
      checkLoggedInBulkQual(
        this.props.dispatch,
        this.props.loopQualApp,
        this.props.fiosDetails,
        this.props.addressQualification,
        this.props.customerStreetDetails,
        this.props.unitLookupDetails,
        this.props.moveDetails,
        this.props.cookies,
        false,
        this.props.bulkQualDetails,
      );
    }
    const isCPC = this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC';
    const canGoResponsiveLQNetworkAvail = this.isResponsiveLQMini && isAddressQualification && isCPC;
    if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC' && this.isResponsiveLQMini && !stickyMiniBtn) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.props.dispatch(Action.updateHideMiniLq(false));
      this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
      this.props.dispatch(showLoader());
      openModalWindow('LQAPP', this.props.dispatch);
      if (isMYVZWAPP()) {
        pageViewPageVzTag('pmd_landing', 'lq check avail');
      }
      if (bulkPlanVisited) {
        checkLoggedIn(this.props.dispatch, this.props.cookies, this.props.loopQualApp, '', this.props.addressQualification, this.props.moveDetails);
      } else {
        this.props.dispatch(Action.updateIsBulkQual(true));
        setToStorage('FWALqAALThrottle', 'BULK');
        this.props.dispatch(updateHideMiniLq(false));
        checkLoggedInBulkQual(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      }
      return;
    }
    if ((this.isResponsiveLQ && !this.isResponsiveLQMini) || canGoResponsiveLQNetworkAvail) {
      const responsiveLQBulkRes = !!window.sessionStorage.getItem('ResponsiveLQBulkRes');
      const clearDetailsLQ = window.sessionStorage.getItem('clearDetailsLQ');
      this.props.dispatch(Action.updateHideMiniLq(false));
      this.props.dispatch(Action.updateSkeleton(false));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setTimeout(() => {
        // greyOut();
        const streetInput = document.querySelector('#streetaddress');
        if (streetInput) {
          streetInput.focus();
        }
        setTimeout(() => {
          greyOut();
        }, 500);
      }, 500);
      if (!this.props.loopQualApp.isLoggedIn || clearDetailsLQ || canGoResponsiveLQNetworkAvail) {
        if (!canGoResponsiveLQNetworkAvail) {
          if (this.props.customerStreetDetails) openModalWindow('LQAPP', this.props.dispatch);
        }
        if (!lqResponseStorage || canGoResponsiveLQNetworkAvail) {
          inVokeLqCheckNetworkAvailability(
            e,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.addressQualification,
            this.props.loopQualApp,
            this.props.dispatch,
            this.props.cookies,
            this.props.streetInput,
            this.props.fiosDetails,
            this.props.moveDetails,
            null,
            this.props.parserAttempt,
            true,
          );
        }
      } else if (this.props.loopQualApp.isLoggedIn) {
        openModalWindow('LQAPP', this.props.dispatch);
        if (!responsiveLQBulkRes) {
          this.props.dispatch(Action.updateIsBulkQual(true));
          this.props.dispatch(showLoader());
          this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
          checkLoggedInBulkQual(
            this.props.dispatch,
            this.props.loopQualApp,
            this.props.fiosDetails,
            this.props.addressQualification,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.moveDetails,
            this.props.cookies,
            false,
            this.props.bulkQualDetails,
          );
        }
      }
    } else if (
      (window.location.search &&
        (window.location.search.toLowerCase().indexOf('move=y') > -1 || window.location.search.toLowerCase().indexOf('type=moving') > -1)) ||
      this.props.loopQualApp.flowType === Flowtype.MOVERS
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      if (!this.props.loopQualApp.isLoggedIn && !this.props.loopQualApp.isLoggedInUserInfo) {
        existingCustomerModal(this.props.dispatch, true);
      } else if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW)
        this.updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW);
    } else if (
      this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.isBulkQual &&
      this.props.bulkQualDetails.bulkProfileDetails &&
      this.props.loopQualApp.isNSAFlow &&
      this.props.loopQualApp.currentLQView !== LQ_VIEWS.ADDRESS_QUALIFICATION && // CXTDT-526128 Empty Billing and unQualified to suppress open modal
      !this.props.addressQualification.lqsTagTriggered
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      if (this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW && !this.props.bulkQualDetails.singleFromBulk) {
        lqsTagTriggered(this.props.dispatch, true);
        if (isMYVZWAPP()) {
          pageViewPageVzTag('pmd_landing', `plq result -multi${this.props.bulkQualDetails.qualifiedlist}`);
        } else {
          openViewVzTag(`plq result -multi${this.props.bulkQualDetails.qualifiedlist}`);
        }
      } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
        sendDataVzTagLqs('single', this.props.bulkQualDetails.preferredbulkServiceDetail);
        if (
          this.props.bulkQualDetails.preferredbulkServiceDetail &&
          this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType &&
          this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType !== ''
        ) {
          logMetrics('bulkQualAddressType', this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType, 'Info');
        }
      } else if (
        lqResponseStorage &&
        this.props.bulkQualDetails.singleFromBulk &&
        !this.props.lqResult.uberPinEligible &&
        (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified)
      ) {
        if (config.autoQualOrderingRedirect === false || this.props.loopQualApp.TWS !== null || this.props.miniLq.enabled === 'false') {
          openModalWindow('LQAPP', this.props.dispatch);
        } else {
          lqsTagTriggered(this.props.dispatch, true);
          if (
            this.props.bulkQualDetails.preferredbulkServiceDetail &&
            this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType &&
            this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType !== ''
          ) {
            logMetrics('bulkQualAddressType', this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType, 'Info');
          }
          const response = JSON.parse(lqResponseStorage);
          const passiveLqsSuppression =
            !loopQualApp.TWS &&
            !loopQualApp.isFccFlow &&
            !loopQualApp.isACPFlow &&
            !response.uberPinEligible &&
            loopQualApp.flowType !== Flowtype.storeLocater &&
            loopQualApp.flowType !== Flowtype.MOVERS &&
            loopQualApp.flowType !== Flowtype.retail &&
            !loopQualApp.isComboMFJT &&
            !loopQualApp.isECComboMFJT &&
            !loopQualApp.newConnection;
          // if (passiveLqsSuppression) {
          this.props.dispatch(showLoader());
          this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
          // updatePlanpageViaThrottle(response?.output, this.props.dispatch, this.props.loopQualApp, false, false, false, true);
          // } else {
          if (!passiveLqsSuppression) {
            sendDataVzTagLqs('single', this.props.bulkQualDetails.preferredbulkServiceDetail);
          }
          orderNowFiveGNsa(
            this.props.addressQualification,
            loopQualApp,
            this.props.dispatch,
            true,
            this.props.moveDetails,
            false,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.fiosDetails,
            false,
            true,
            this.props.bulkQualDetails.preferredbulkServiceDetail,
            false,
            true,
          );
          // }
        }
      }
    } else if (lqResponseStorage && this.props.addressQualification.lqsTagTriggered && loopQualApp.plansPageUrl !== '') {
      if (
        this.props.lqResult &&
        ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW && this.props.lqResult.fiosQualified) ||
          ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW || loopQualApp.currentLQView === LQ_VIEWS.BLANK_LOADER_VIEW) &&
            this.props.lqResult.fiosExtremeHappy) ||
          (loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand)) ||
          (loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW && this.props.lqResult.lteQualified) ||
          (loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified)))
      ) {
        if (config.autoQualOrderingRedirect === false) {
          openModalWindow('LQAPP', this.props.dispatch);
        } else if (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified) {
          openModalWindow('LQAPP', this.props.dispatch);
          if (this.props.loopQualApp.isACPFlow) {
            const languageType = window.location.host.indexOf('espanol') > -1 ? 'S' : 'E';
            const emreiosFetchBody = {
              registrationLanguage: languageType,
              businessLine: 'FWA',
              customerType: this.props?.loopQualApp?.isLoggedIn ? 'E' : 'N',
              fiosSessionId: '',
              redirectUrl: this.props?.loopQualApp?.isLoggedIn
                ? `https://${window.location.host}/sales/home/progressiveplan.html?ec=y&acp=y&intcmp=vf3-plansoverview-continue`
                : `https://${window.location.host}/sales/home/progressiveplan.html?acp=y&intcmp=vf3-plansoverview-continue`,
              nonAALFlag: false,
            };
            invokeUCAbandonedCart(
              this.props.addressQualification,
              this.props.loopQualApp,
              this.props.dispatch,
              true,
              emreiosFetchBody,
              this.props.moveDetails,
              this.props.customerStreetDetails,
              this.props.unitLookupDetails,
              this.props.fiosDetails,
              this.props.bulkQualDetails.preferredbulkServiceDetail,
              this.props.bulkQualDetails.bulkProfileDetails,
            );
          } else if (!this.props.lqResult.uberPinEligible) {
            orderNowFiveGNsa(
              this.props.addressQualification,
              loopQualApp,
              this.props.dispatch,
              true,
              this.props.moveDetails,
              false,
              this.props.customerStreetDetails,
              this.props.unitLookupDetails,
              this.props.fiosDetails,
              false,
              false,
            );
          }
        } else if (loopQualApp.currentLQView === LQ_VIEWS.LBO_VIEW || (this.props.lqResult.fiosQualified && !this.props.lqResult.fiosExtremeHappy)) {
          openModalWindow('LQAPP', this.props.dispatch);
        } else {
          redirectToPlansPage(loopQualApp.plansPageUrl);
        }
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        this.invokeInitTag(false, 'checkAvaillqInit', 'lq check avail', false, this.props.loopQualApp);
        if (loopQualApp.currentLQView !== LQ_VIEWS.LBO_VIEW) {
          this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
        }
      }
    } else if (
      (lqResponseStorage && this.props.addressQualification.lqsTagTriggered) ||
      (this.props.lqResult &&
        ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW && this.props.lqResult.fiosQualified) ||
          ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW || loopQualApp.currentLQView === LQ_VIEWS.BLANK_LOADER_VIEW) &&
            this.props.lqResult.fiosExtremeHappy) ||
          (loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand)) ||
          (loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW && this.props.lqResult.lteQualified) ||
          (loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified))))
    ) {
      if (this.props.lqResult.fiosExtremeHappy) {
        let redirectUrl = window.location.host.includes('.verizonwireless.com')
          ? `https://www98.verizon.com${Pages.InHomeBuildProduct}&visitor_id=${this.props.addressQualification.lqResult.visitor_id}&visit_id=${this.props.addressQualification.lqResult.visit_id}`
          : Pages.InHomeBuildProduct;
        if (loopQualApp?.homeLQ) {
          redirectUrl = `${redirectUrl}&homelq=y`;
        }
        redirectToFiosPlansPage(redirectUrl, this.props.dispatch);
      } else if (
        !loopQualApp.isLoggedIn &&
        !loopQualApp.TWS &&
        !loopQualApp.isFccFlow &&
        !loopQualApp.isACPFlow &&
        !this.props.lqResult.uberPinEligible &&
        loopQualApp.flowType !== Flowtype.storeLocater &&
        loopQualApp.flowType !== Flowtype.MOVERS &&
        loopQualApp.flowType !== Flowtype.retail &&
        !loopQualApp.isComboMFJT &&
        !loopQualApp.isECComboMFJT &&
        !loopQualApp.newConnection &&
        (loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW || loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW)
      ) {
        orderNowFiveGNsa(
          this.props.addressQualification,
          loopQualApp,
          this.props.dispatch,
          true,
          this.props.moveDetails,
          false,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.fiosDetails,
          false,
          false,
        );
      } else if (this.props.loopQualApp?.homeLQ && this.props.lqResult?.uberPinEligible) {
        this.updateCurrentView(LQ_VIEWS.HERE_MAP_VIEW);
        openModalWindow('HOMELQ', this.props.dispatch);
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
      }
      if (
        this.props.loopQualApp.isLoggedIn &&
        this.props.loopQualApp.roleType &&
        (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
      ) {
        this.updateCurrentView(LQ_VIEWS.LBO_VIEW); // Showing LBO for prepay guest pre loggedIn LQS customer
      }
      this.invokeInitTag(true, 'checkAvaillqInit', 'lq check avail', false, loopQualApp);
    } else if (lqResponseStorage && loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
      openModalWindow('LQAPP', this.props.dispatch);
      this.updateCurrentView(LQ_VIEWS.UN_QUALIFIED_VIEW);
      this.invokeInitTag(true, 'checkAvaillqInit', 'lq check avail', false, loopQualApp);
    } else if (
      loopQualApp.isLoggedIn &&
      loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW &&
      !this.props.bulkQualDetails.singleFromBulk
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      openViewVzTag(`plq result -multi${this.props.bulkQualDetails.qualifiedlist}`);
    } else if (
      (this.isTwsFilterdFlow && !this.props.customerStreetDetails && (this.props.loopQualApp.TWS || this.props.loopQualApp.isACPFlow)) ||
      (!this.isTwsFilterdFlow && (this.props.loopQualApp.TWS || this.props.loopQualApp.isACPFlow))
    ) {
      let subFlowType = 'lq check avail';
      if (this.isTwsFilterdFlow) {
        // openModalWindow('LQAPP', this.props.dispatch);
        this.props.dispatch(Action.updateHideMiniLq(false));
        this.props.dispatch(Action.updateSkeleton(false));
        this.props.dispatch(Action.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
        setTimeout(() => {
          greyOut();
        }, 200);
        const streetInput = document.querySelector('#streetaddress');
        if (streetInput) {
          streetInput.focus();
        }
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        let currentView = this.props.loopQualApp.currentLQView;
        if (
          this.props.loopQualApp.isLoggedIn &&
          (this.props.addressQualification?.lqResult?.existingFWA ||
            window.sessionStorage.getItem('existingFwa') === 'Y' ||
            this.props.bulkQualDetails.preferredbulkServiceDetail?.existingFWA) &&
          window.sessionStorage.getItem('isVfExist') === 'false'
        ) {
          if (window.sessionStorage.getItem('isVfEmail') === 'true') {
            currentView = LQ_VIEWS.VF_QUAL_FWA;
          } else {
            currentView = LQ_VIEWS.VF_QUESTIONAIRE;
          }
        } else if (!this.props.loopQualApp.isLoggedIn && window.sessionStorage.getItem('isVfEmailNC') === 'true') {
          currentView = LQ_VIEWS.ADDRESS_QUALIFICATION;
        } else if (!this.props.loopQualApp.isLoggedIn) {
          currentView = LQ_VIEWS.COMMON_LQ;
        }
        this.updateCurrentView(currentView);
        subFlowType =
          window.sessionStorage.getItem('isVfEmailNC') === 'true'
            ? ' lq check avail | vfemail'
            : this.props.loopQualApp.currentLQView === 'VF_QUESTIONAIRE'
            ? 'thank you for being loyal customer'
            : 'lq check avail';
      }
      this.invokeInitTag(false, 'checkAvaillqInit', subFlowType, false, this.props.loopQualApp);
    } else if (
      this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.roleType &&
      (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      this.updateCurrentView(LQ_VIEWS.LBO_VIEW);
    } else if (this.props.loopQualApp.lq4 || this.props.loopQualApp.homeLQ) {
      inVokeLqCheckNetworkAvailability(
        e,
        this.props.customerStreetDetails,
        this.props.unitLookupDetails,
        this.props.addressQualification,
        this.props.loopQualApp,
        this.props.dispatch,
        this.props.cookies,
        this.props.streetInput,
        this.props.fiosDetails,
        this.props.moveDetails,
        null,
        this.props.parserAttempt,
        true,
      );
    } else if (this.props.loopQualApp.isComboMFJT && !this.props.loopQualApp.isLoggedIn && !this.props.loopQualApp.isLoggedInUserInfo) {
      openModalWindow('LQAPP', this.props.dispatch);
    } else if (this.props.loopQualApp.isECComboMFJT && this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.isLoggedInUserInfo) {
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      const newConnection = params.get('PN')
        ? params.get('PN')
        : window.sessionStorage.getItem('couponToken')
        ? window.sessionStorage.getItem('couponToken')
        : null;
      openModalWindow('LQAPP', this.props.dispatch);
      if (newConnection) {
        this.updateCurrentView(LQ_VIEWS.COMMON_LQ);
      } else if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC') {
        this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
        this.props.dispatch(showLoader());
      } else if (loopQualApp.currentLQView !== LQ_VIEWS.LBO_VIEW) {
        this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
      }
      if (this.props.loopQualApp.sourceInfo?.toUpperCase() !== 'CPC') {
        this.invokeInitTag(false, 'checkAvaillqInit', 'lq check avail', false, this.props.loopQualApp);
      }
    }
    const seePlanCtaLearn = document.querySelector('.w_modal.m_large.vzrf.active .modal_x');
    if (seePlanCtaLearn) {
      seePlanCtaLearn.click();
    }
    if (this.props.loopQualApp.sourceInfo === 'DigitalCrossSell' && this.props.loopQualApp.mhToken === null && window.mhToken) {
      this.props.dispatch(Action.updateMhToken(window.mhToken));
    }
    const unitErrorElem = document.getElementById('uniterror');
    if (unitErrorElem) {
      unitErrorElem.focus();
    }
  };

  hideLQ() {
    if (window?.vzlqwPlan?.lqAddressShow) {
      window.vzlqwPlan.lqAddressShow();
    }
    this.props.dispatch(updateHideMiniLq(true));
  }

  render() {
    const sessionTimeoutFlowEnabled = canApplySessionTimeout(this.props.loopQualApp, Flowtype);
    return (
      <>
        {sessionTimeoutFlowEnabled && (
          <TimerInitialize
            runTimeKey={this.props.loopQualApp?.runTimeKey}
            sessionTimeoutData={this.props.loopQualApp?.staticContent?.SESSION_TIMEOUT}
          />
        )}
        {this.renderView()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loopQualApp: state.loopQualApp,
  loaderStatus: state.loader.loaderStatus,
  customerStreetDetails: state.addressLookup.customerStreetDetails,
  revisitAddress: state.addressLookup.revisitAddress,
  streetInput: state.addressLookup.streetInput,
  addressLookup: state.addressLookup,
  unitInputError: state.unitLookup.unitInputError,
  unitLookupDetails: state.unitLookup,
  addressQualification: state.addressQualification,
  modalOverlay: state.modalOverlay,
  lqResult: state.addressQualification.lqResult || {},
  multiServiceDetails: state.multiServiceDetails,
  isLoggedIn: state.loopQualApp.isLoggedIn,
  fiosDetails: state.fiosDetails,
  moveDetails: state.moveDetails,
  notify: state.notify,
  bulkQualDetails: state.bulkQualDetails,
  parserAttempt: state.addressLookup.parserAttempt,
  sessionTimeout: state.sessionTimeout,
});
export default withCookies(connect(mapStateToProps)(LoopQualApp));
LoopQualApp.propTypes = {
  loopQualApp: PropTypes.string,
  streetInput: PropTypes.string,
  addressLookup: PropTypes.string,
  dispatch: PropTypes.string,
  unitLookupDetails: PropTypes.string,
  externalTrigger: PropTypes.string,
  lqResult: PropTypes.string,
  mode: PropTypes.string,
  isLoggedIn: PropTypes.string,
  miniLq: PropTypes.string,
  addressQualification: PropTypes.string,
  customerStreetDetails: PropTypes.string,
  modalOverlay: PropTypes.string,
  revisitAddress: PropTypes.string,
  cookies: PropTypes.string,
  multiServiceDetails: PropTypes.string,
  loaderStatus: PropTypes.string,
  bulkQualDetails: PropTypes.object,
  moveDetails: PropTypes.object,
  fiosDetails: PropTypes.object,
  notify: PropTypes.object,
  parserAttempt: PropTypes.object,
  unitInputError: PropTypes.bool,
  externalSignInTrigger: PropTypes.any,
  sessionTimeout: PropTypes.any,
};
