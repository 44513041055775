import { LQ_VIEWS } from '../../constants/common';
import { LoopQualification as Action } from '../../constants/actionTypes';

const initialState = {
  flowType: '',
  isNSAFlow: true,
  currentLQView: LQ_VIEWS.ADDRESS_QUALIFICATION,
  homeLQView: '',
  sessionTimeoutView: '',
  showSkeleton: true,
  captchaDetails: {
    reCaptchaEnabled: 'N',
    reCaptchaVerified: false,
    reCaptchaJS: '',
    reCaptchaSiteKey: '',
    gCaptchaResponse: '',
  },
  widgetHost: '',
  lqPlatform: '',
  lq4: false,
  homeLQ: false,
  autoGeo: false,
  newConnection: false,
  TWS: null,
  isACPFlow: false,
  isVfEc: false,
  isMvph2: false,
  isMvph2Titan: false,
  disableMoversPh2: false,
  hideFccMiniLq: false,
  isFccFlow: false,
  isComboMFJT: false,
  isECComboMFJT: false,
  isNycPh2: false,
  isBulkQual: false,
  sourceInfo: null,
  isComboEmailflow: false,
  isSeqFwaEmailflow: false,
  mhToken: null,
  isLoggedIn: false,
  isLoggedInUserInfo: false,
  roleType: '',
  fiosVzt: false,
  landedPlansPage: false,
  plansPageUrl: '',
  staticContent: '',
  checkAvailBtnTrack: 'check avail - nav',
  runTimeKey: null,
  couponLQ: '',
  throttleResponse: null,
  validCouponFlow: false,
  customerType: '',
  midnight: true,
  isResponsiveLQ: false,
  isVFEmailNc: false,
  isVFResumeFlow: false,
  isEnrollTrue: '',
  isFromPlansLq: false,
};

const homeLQModals = ['LBO_VIEW', 'HOME_HELP_VIEW', 'RESUME_SEND_CART_VIEW', 'IN_SERVICE_VIEW', 'HERE_MAP_VIEW', 'SESSION_TIMEOUT_VIEW'];
function loopQualAppReducer(state = initialState, action) {
  switch (action.type) {
    case Action.SET_FLOW_TYPE:
      return { ...state, flowType: action.value };
    case Action.UPDATE_CURRENT_VIEW:
      return {
        ...state,
        currentLQView:
          state?.homeLQ && homeLQModals.includes(action.value)
            ? state.currentLQView
            : action.value === 'SESSION_TIMEOUT_VIEW'
            ? state.currentLQView
            : action.value,
        homeLQView: state?.homeLQ ? (homeLQModals.includes(action.value) ? action.value : '') : '',
        showLboViewModal: state?.homeLQ ? action.value === 'LBO_VIEW' : false,
        sessionTimeoutView: state?.homeLQ ? '' : action.value === 'SESSION_TIMEOUT_VIEW' ? action.value : '',
      };
    case Action.CLEAR_LBO_MODAL:
      return {
        ...state,
        showLboViewModal: false,
      };
    case Action.UPDATE_HOME_LQ_DIMENSIONS:
      return {
        ...state,
        homeLQ: { ...state.homeLQ, dimensions: { ...state.homeLQ.dimensions, ...action.value } },
      };
    case Action.UPDATE_RECAPTCHA_DETAILS:
      return { ...state, captchaDetails: action.value };
    case Action.UPDATE_RECAPTCHA_RESPONSE:
      return { ...state, captchaDetails: { ...state.captchaDetails, gCaptchaResponse: action.value } };
    case Action.UPDATE_RECAPTCHA_PROPERTY:
      return { ...state, captchaDetails: { ...state.captchaDetails, ...action.value } };
    case Action.SET_LQ_PLATFORM:
      return { ...state, lqPlatform: action.value };
    case Action.SET_LQ4:
      return { ...state, lq4: action.value };
    case Action.SET_HOMELQ:
      return { ...state, homeLQ: action.value };
    case Action.SET_AUTOGEO:
      return { ...state, autoGeo: action.value };
    case Action.SET_NEW_CONNECTION:
      return { ...state, newConnection: action.value };
    case Action.SET_TWS:
      return { ...state, TWS: action.value };
    case Action.SET_ACP:
      return { ...state, isACPFlow: action.value };
    case Action.SET_VFEC:
      return { ...state, isVfEc: action.value };
    case Action.SET_MVPH2:
      return { ...state, isMvph2: action.value };
    case Action.SET_MVPH2_TITAN:
      return { ...state, isMvph2Titan: action.value };
    case Action.SET_MVPH2_DISABLE:
      return { ...state, disableMoversPh2: action.value };
    case Action.HIDE_FCC_MINILQ:
      return { ...state, hideFccMiniLq: action.value };
    case Action.IS_FCC_FLOW:
      return { ...state, isFccFlow: action.value };
    case Action.MIDNIGHT:
      return { ...state, midnight: action.value };
    case Action.SET_COMBOMFJT:
      return { ...state, isComboMFJT: action.value };
    case Action.SET_EC_COMBOMFJT:
      return { ...state, isECComboMFJT: action.value };
    case Action.SET_NYCPH2:
      return { ...state, isNycPh2: action.value };
    case Action.SET_BULKQUAL:
      return { ...state, isBulkQual: action.value };
    case Action.SET_MHTOKEN:
      return { ...state, mhToken: action.value };
    case Action.SET_SOURCE_INFO:
      return { ...state, sourceInfo: action.value };
    case Action.SET_COMBO_EMAIL_FLOW:
      return { ...state, isComboEmailflow: action.value };
    case Action.SET_SEQ_FWA_EMAIL_FLOW:
      return { ...state, isSeqFwaEmailflow: action.value };
    case Action.UPDATE_LOGGED_IN:
      return { ...state, isLoggedIn: action.value };
    case Action.UPDATE_LOGGED_IN_USERINFO:
      return { ...state, isLoggedInUserInfo: action.value };
    case Action.ROLE_TYPE:
      return { ...state, roleType: action.value };
    case Action.FIOS_VZT:
      return { ...state, fiosVzt: action.value };
    case Action.UPDATE_WIDGET_HOST:
      return { ...state, widgetHost: action.value };
    case Action.UPDATE_COMMON_LQ:
      return { ...state, couponLQ: action.value };
    case Action.REDIRECT_TO_PLANS_PAGE:
      return {
        ...state,
        landedPlansPage: action.value.redirected,
        plansPageUrl: action.value.pageUrl,
      };
    case Action.SET_STATIC_CONTENT:
      return { ...state, staticContent: action.value };
    case Action.SET_CHECKAVAILBTNTRACK:
      return { ...state, checkAvailBtnTrack: action.value };
    case Action.SET_RUNTIME_KEY:
      return { ...state, runTimeKey: action.value };
    case Action.UPDATE_THROTTLE_RESPONSE:
      return { ...state, throttleResponse: action.value };
    case Action.UPDATE_VALID_COUPONFLOW:
      return { ...state, validCouponFlow: action.value };
    case Action.UPDATE_CUSTOMER_TYPE:
      return { ...state, customerType: action.value };
    case Action.SET_RESP_LQ:
      return { ...state, isResponsiveLQ: action.value };
    case Action.SET_VF_EMAIL_NC:
      return { ...state, isVFEmailNc: action.value };
    case Action.SET_VF_RESUME_FLOW:
      return { ...state, isVFResumeFlow: action.value };
    case Action.SET_VF_ENROLL_CHECK:
      return { ...state, isEnrollTrue: action.value };
    case Action.UPDATE_SKELETON_VIEW:
      return { ...state, showSkeleton: action.value };
    case Action.UPDATE_FROM_PLANS_LQ:
      return { ...state, isFromPlansLq: action.value };
    default:
      return state;
  }
}

export default loopQualAppReducer;
