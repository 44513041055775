
export const queryStringToObj = () => {
    const queryStrings = window.location.search && window.location.search.substring(1);
    const regex = /^(true|false)/g;
    const obj = {};
    if (queryStrings) {
      queryStrings.replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
        obj[decodeURIComponent(key)] = regex.test(value)
          ? value === 'true'
          : decodeURIComponent(value);
      });
    }
    return obj;
  };
  // const queryObj = queryStringToObj();
  // const flowType = (queryObj.flowType || '').toLowerCase();
  
  export const generateQueryString = (params, append) => {
    let query = '';
    if (Object.keys(params).length > 0) {
      query = (append ? '&' : '?') + Object.keys(params).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');
    }
    return query;
  };
  
  export const checkTestFlow = () => {
    const queryStrings = window.location.search && window.location.search.substring(1);
    if (!queryStrings) return false;
  
    if (queryStrings.indexOf('testQualification') > -1 || queryStrings.indexOf('nsa=y') > -1 || queryStrings.indexOf('NSA=Y') > -1) {
      return true;
    }
  
    return false;
  };