/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconLocation from '@vds/icons/location';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { showLoader, hideLoader } from './common/loader/actions';
import Link from './common/Link';
import { MethodType } from '../constants/common';
import callingApi from '../services/apiService';
import { apiUrl } from '../constants/api-urls';
import { dataTrackwithDetail } from '../utils/vzdl';
import { linkVzTag, loopQualVzTaglqa, openViewVzTag } from '../utils/unifiedVzTag';
import { logMetrics } from '../services/logService';
import { setAddressDetailForGeo, setStreetGeoError, updateStreetInput } from './address-lookup/actions';
import { checkNetworkAvailability } from './address-qualification/actions';
import { updateAutoGeo, updateCurrentView, updateSkeleton } from './loop-qual-app/actions';
import { openModalWindow } from './common/modal/action';

const GeoIcon = styled.div`
  cursor: pointer;
`;
const GeoLocation = styled.div`
  &:focus {
    outline-style: dotted;
    outline-color: ${(props) => props.color} !important;
  }
  &:active:focus {
    outline-style: none;
  }
  p {
    a {
      color: ${(props) => props.color} !important;
    }
  }
  &#location {
    color: $ svg {
      path {
        fill: ${(props) => props.color} !important;
      }
    }
  }
`;
function Location(props) {
  const [showoptions, setShowoptions] = useState(true);
  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
        if (PermissionStatus.state === 'blocked' || PermissionStatus.state === 'denied') {
          setShowoptions(false);
        }
      });
    }
  }, []);
  function handleLocationClick() {
    props.dispatch(showLoader());
    props.dispatch(updateSkeleton(true));
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log('Geolocation not supported');
      props.dispatch(hideLoader());
      props.dispatch(updateSkeleton(false));
    }
  }

  function invokeWirelessLocusApi(latitude, longitude) {
    const phase1 = false;
    const url = apiUrl().getStreetWithGeo;
    const data = {
      longitude,
      latitude,
      includeXrefs: true,
    };
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve) => {
      callingApi(url, MethodType.POST, data, 'application/json', requiredflowType)
        .then((response) => {
          props.dispatch(hideLoader());
          props.dispatch(updateSkeleton(false));
          const formattedAddress = response?.data?.addressInfo?.[0]?.standardizedAddress;
          if (
            phase1 && // ACT111-3406 - disabling this condition for LQ 4.0 Geo Locator Direct to Ordering flows
            formattedAddress &&
            formattedAddress.addressLine1 &&
            formattedAddress.city &&
            formattedAddress.stateProvince &&
            formattedAddress.postalCodeBase &&
            formattedAddress.iso3CountryCode &&
            formattedAddress.iso3CountryCode === 'USA'
          ) {
            props.speechonChange(
              `${formattedAddress.addressLine1} ${formattedAddress.city} ${formattedAddress.stateProvince} ${formattedAddress.postalCodeBase}`,
            );
            logMetrics('GeoLocator', 'called from locus', 'Info');
          } else if (
            formattedAddress &&
            formattedAddress.addressLine1 &&
            formattedAddress.city &&
            formattedAddress.stateProvince &&
            formattedAddress.postalCodeBase &&
            formattedAddress.iso3CountryCode &&
            formattedAddress.iso3CountryCode === 'USA'
          ) {
            props.dispatch(updateAutoGeo(true));
            // const addressClassification = response?.data?.addressInfo?.[0]?.addressClassification;
            props.dispatch(updateStreetInput(formattedAddress.formattedAddress));
            const addrInfo = setAddressDetailForGeo(props.dispatch, formattedAddress);
            const { isSublocAvailable } = formattedAddress;
            const PropertyType = response?.data?.addressInfo?.[0]?.PropertyType;
            if (PropertyType && PropertyType === 'B' && isSublocAvailable === false && !props.loopQualApp.homeLQ) {
              props.dispatch(updateCurrentView('BUSINESSADDRESS_VIEW'));
              openModalWindow('LQAPP', props.dispatch);
            } else if (isSublocAvailable === false) {
              openViewVzTag('lq check avail');
              logMetrics('autogeoqual', 'Calling checkNetworkAvailability for SFU SBU', 'Info');
              setTimeout(() => {
                loopQualVzTaglqa('lq address verification |use my location');
              }, 500);
              checkNetworkAvailability(
                addrInfo,
                props.unitLookup,
                props.addressQualification,
                props.loopQualApp,
                props.dispatch,
                props.cookies,
                '',
                props.fiosDetails,
                props.moveDetails,
                null,
                true,
                true,
                true,
                false,
                true, // from autoGeoQualLink
              );
            }
            logMetrics('GeoLocator', 'called from locus', 'Info');
          } else {
            props.dispatch(setStreetGeoError(true));
          }
          resolve(response);
        })
        .catch((error1) => {
          console.log(error1);
          props.dispatch(setStreetGeoError(true));
          resolve(error1);
          props.dispatch(updateSkeleton(false));
        });
    });
  }
  function success(position) {
    linkVzTag('location allowed');
    logMetrics('GeoLocator', 'allow', 'Info');
    props.speechonChange(`  `);
    props.dispatch({ type: 'UPDATE_ADDRESS_COLLECTION_RESET', value: [] });
    props.dispatch(showLoader());
    props.dispatch(updateSkeleton(true));
    const { latitude } = position.coords;
    const { longitude } = position.coords;

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    invokeWirelessLocusApi(latitude, longitude);
  }

  function error(e) {
    switch (e.code) {
      case e.PERMISSION_DENIED:
        window.sessionStorage.setItem('GeoQualBlock', 'Y');
        setShowoptions(false);
        linkVzTag('location blocked');
        logMetrics('GeoLocator', 'block', 'Info');
        break;
      default:
        window.sessionStorage.setItem('GeoQualBlock', 'Y');
        setShowoptions(false);
        linkVzTag('location Error');
        logMetrics('GeoLocator', 'Error', 'Info');
        break;
    }
    console.log('Unable to retrieve your location');
    props.dispatch(hideLoader());
    props.dispatch(updateSkeleton(false));
  }

  return (
    <div>
      {showoptions && (
        <GeoLocation
          id="location"
          color={props.colors?.color || '#000'}
          tabIndex="0"
          onKeyPress={() => handleLocationClick()}
          style={{ display: 'flex', float: 'left', right: '0px', top: '140px', gap: '3px', color: props?.colors?.color }}
        >
          <Link
            onClick={() => handleLocationClick()}
            onKeyPress={() => handleLocationClick()}
            linkText="Use my location"
            role="button"
            tabIndex="-1"
            style={{ marginBottom: '6px' }}
            surface={props.colors?.surface || (props.fromSticky ? 'dark' : 'light')}
            data-track={
              props.loopQualApp.homeLQ
                ? dataTrackwithDetail('link', 'use my location', 'lq banner')
                : props.fromSticky
                ? dataTrackwithDetail('link', 'use my location', 'lq sticky bar')
                : dataTrackwithDetail('link', 'use my location', 'lq check avail')
            }
          />
          <GeoIcon
            id="location"
            className={props.colors?.surface || ''}
            // tabIndex="0"
            // role="button"
            // onClick={() => handleLocationClick()}
            onKeyPress={() => handleLocationClick()}
          >
            <IconLocation ariaHidden="true" color={props.colors?.color || (props.fromSticky ? '#FFFFFF' : '#000000')} size="small" />
          </GeoIcon>
        </GeoLocation>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  addressQualification: state.addressQualification,
  unitLookup: state.unitLookup,
  fiosDetails: state.fiosDetails,
  moveDetails: state.moveDetails,
});

export default withCookies(connect(mapStateToProps)(Location));
Location.propTypes = {
  speechonChange: PropTypes.func,
  dispatch: PropTypes.func,
  fromSticky: PropTypes.bool,
  colors: PropTypes.any,
  loopQualApp: PropTypes.any,
  unitLookup: PropTypes.any,
  addressQualification: PropTypes.any,
  fiosDetails: PropTypes.any,
  moveDetails: PropTypes.any,
  cookies: PropTypes.string,
};
