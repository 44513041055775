import * as ACTIONS from './action';

const initialState = {
  timer: 0,
  loggedInExtendedView: false,
  loggedInExpiredView: false,
  prospectExpiredView: false,
  sessionExpired: false,
  staySignedIn: false,
  elapseTimer: 0,
  warningTimer: 0,
  mainTimeStamp: 0,
};
function resetExpiredView() {
  return { loggedInExtendedView: false, loggedInExpiredView: false, prospectExpiredView: false, sessionExpired: false, staySignedIn: false };
}
function sessionTimeoutView(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.prospectExpiredView.type:
      return {
        ...state,
        ...resetExpiredView(),
        sessionExpired: true,
        prospectExpiredView: true,
        timeStamp: new Date().getTime(),
      };
    case ACTIONS.loggedInExtendedView.type:
      return {
        ...state,
        ...resetExpiredView(),
        sessionExpired: true,
        loggedInExtendedView: true,
        timeStamp: new Date().getTime(),
      };
    case ACTIONS.loggedInExpiredView.type:
      return {
        ...state,
        ...resetExpiredView(),
        sessionExpired: true,
        loggedInExpiredView: true,
        timeStamp: new Date().getTime(),
      };
    case ACTIONS.setMainTimeStamp.type:
      return { ...state, mainTimeStamp: action.payload };
    case ACTIONS.setTimerValue.type:
      return { ...state, timer: action.payload, elapseTimer: action.payload };
    case ACTIONS.updateElapseView.type:
      return { ...state, elapseTimer: action.payload };
    case ACTIONS.resetElapseTimer.type:
      return { ...state, elapseTimer: state.timer };
    case ACTIONS.setWarningValue.type:
      return { ...state, warningTimer: action.payload };
    case ACTIONS.resetSessionView.type:
      return {
        ...state,
        ...resetExpiredView(),
      };
    case ACTIONS.setStaySignedIn.type:
      return {
        ...state,
        ...resetExpiredView(),
        staySignedIn: action.payload,
      };
    default:
      return state;
  }
}
export default sessionTimeoutView;
