import { updateHomeLQDimensions } from '../components/loop-qual-app/actions';

export function isNotNull(value) {
  if (typeof value !== 'string' && value !== undefined && value !== null) {
    return true;
  }
  if (typeof value === 'string' && value !== undefined) {
    return true;
  }
  return false;
}

export function setModalScroll(offset = 0) {
  const scrollEl = document.querySelector('#lqw-modal-container #scrollbar-view') || document.querySelector('#lqw-modal-container');
  if (scrollEl) {
    scrollEl.scrollTop = offset;
  }
}

export function covertTime(time) {
  try {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`;
  } catch (e) {
    return '';
  }
}
export function startTimer(time_val, callback, mode = 'M') {
  let timer = '';
  const timerMode = mode === 'M' ? 60 : mode === 'H' ? 3600 : 1;
  let timerVal = time_val * timerMode;
  clearInterval(timer);
  timer = setInterval(() => {
    if (timerVal === 0) {
      clearInterval(timer);
    } else {
      timerVal -= 1;
      callback(timerVal, timer);
    }
  }, 1000);
}

export const getHomeLQImageDimensions = (images, dispatch) => {
  // console.log('items', images);
  // return;
  const totalImages = Object?.keys(images);
  totalImages?.forEach((item) => {
    const prelq = {};
    const dimension = { width: '100%', height: '480px' };
    const img = new Image();
    img.src = images[item];
    img.onload = () => {
      dimension.width = img.naturalWidth;
      dimension.height = img.naturalHeight;
      prelq[item] = dimension;
      if (dispatch) {
        dispatch(updateHomeLQDimensions(prelq));
      }
      // console.log('dimensions', img.naturalWidth, img.naturalHeight);
    };
  });
};

export const parseJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const cloneJSON = (data) => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (e) {
    return null;
  }
};

export const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.defer = true;
  script.onload = callback;
  document.body.appendChild(script);
};

export const redirectLogOutUrl = (preventSessionModal = false) => {
  const params = new URLSearchParams(window.location.search);
  if (!preventSessionModal) {
    params.append('fromLqLogout', 'Y');
  }
  let queryParams = '';
  if (params?.size > 0) {
    queryParams = `?${params.toLocaleString()}`;
  }
  const originPathname = window.location.origin + window.location.pathname;
  if (window.location.host.includes('.verizonwireless.com')) {
    const currentURL = window.location.href;
    let sitURL = 'https://securesit1.verizon.com/signin/user/logout?next=';
    const currentDomain = currentURL.match(/\d+/g);
    if (currentDomain?.length > 0) {
      sitURL = sitURL.replace(/\d+/, currentDomain[0]);
    }
    window.location.href = `${sitURL}${encodeURIComponent(originPathname + queryParams)}`;
  } else if (
    window.location.host === 'www98.verizon.com' ||
    window.location.host === 'wwwawssit.ebiz.verizon.com' ||
    window.location.host === 'wwwnte1aws.ebiz.verizon.com'
  ) {
    window.location.href = `https://securesit1.verizon.com/signin/user/logout?next=${encodeURIComponent(originPathname + queryParams)}`;
  } else {
    window.location.href = `https://secure.verizon.com/signin/user/logout?next=${encodeURIComponent(originPathname + queryParams)}`;
  }
};

export function updateQueryParamURL(param) {
  try {
    const { origin, search, pathname } = window.location;
    const queryParams = new URLSearchParams(search);
    queryParams.delete(param);
    const localString = queryParams.toLocaleString();
    return `${origin + pathname}?${localString}`;
  } catch (err) {
    return null;
  }
}

export const handleStreamlineRedirection = (widgetLQHost = '', replaceValue1 = '', Pages = {}) => {
  let replaceValue = replaceValue1;
  const isStreamlineF = widgetLQHost === 'Streamline_F';
  const isStreamline = widgetLQHost === 'Streamline';
  const isProgressive = replaceValue.includes('home/progressiveplan.html');
  const isInHome = replaceValue.includes('inhome');
  if (isStreamline && isProgressive) {
    replaceValue = Pages.SoeStreamline;
  }
  if (isInHome && isStreamlineF) {
    replaceValue = replaceValue.replace('buildproducts', 'streamlineordering');
  }
  return replaceValue;
};
