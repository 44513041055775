const configDefaults = {
  targetElementId: 'lq-widget-root',
  lqApi: 'LOCUS_API',
  platform: 'ALL',
  scTag: 'wireline',
  tws: '',
  crossSell: '',
  flowType: '',
  seqType: '',
  fiosInquire: '',
  autoQualOrderingRedirect: '',
  renderSettings: {
    mode: 'modal',
    externalTrigger: ['.checkAvailabilityBtn'],
    miniLq: {
      enabled: true,
      footer: '',
      layout: {
        default: {
          topPosition: '69px',
          zIndex: '900',
        },
        mq_lg: {
          breakPoint: '1020px',
          topPosition: '120px',
          zIndex: '899',
        },
      },
    },
    homeLQ: {
      lqTitle: {
        title: 'Verizon Home Internet',
        subTitle: 'Find out whether Fios Home Internet, 5G Home Internet or LTE Home Internet is available in your area.',
        blankTitle: "Let's see what's available.",
      },
      font: {
        family: 'Verizon-NHG-eDS',
      },
      theme: {
        color: '#FFFFFF',
        surface: 'dark',
        btnPrimaryTextColor: '#EE0000',
        skeletonColor: '#bab4b49e',
      },
      banner: {
        height: '460px',
        color: '#EE0000',
        image: {
          preLQView: {
            desktop: 'https://ss7.vzw.com/is/image/VerizonWireless/streamlined-mlp-hero-d?&fmt=png-alpha&scl=1',
            tablet: 'https://ss7.vzw.com/is/image/VerizonWireless/streamlined-mlp-hero-t?&fmt=png-alpha&scl=1',
            mobile: 'https://ss7.vzw.com/is/image/VerizonWireless/streamlined-mlp-hero-m?&fmt=png-alpha&scl=1',
          },
        },
      },
    },
  },
};

function getConfig() {
  return window.vzwlqwidgetconfig || configDefaults;
}

export const getLQPlatform = () => getConfig().platform || configDefaults.platform;

export const getTwsFlow = () => getConfig().tws || configDefaults.tws;

export const getCrossSell = () => getConfig().crossSell || configDefaults.crossSell;

export const getRenderSettings = () => getConfig().renderSettings || configDefaults.renderSettings;

export const getFlowType = () => getConfig().flowType || configDefaults.flowType;

export const getSeqType = () => getConfig().seqType || configDefaults.seqType;

export const getTwsStickyLQ = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const istwsStickyLa = urlSearchParams.get('twsStickyLq');
  return istwsStickyLa || getConfig().twsStickyLq || configDefaults.twsStickyLq;
};

export const getSourceInfo = () => getConfig().sourceInfo || configDefaults.sourceInfo || getConfig().source || configDefaults.source;

export const getResponsiveLQ = () => getConfig().responsiveLQ || configDefaults.responsiveLQ;

export const getFooter = () =>
  (getConfig().renderSettings && getConfig().renderSettings.miniLq && getConfig().renderSettings.miniLq.footer) ||
  (configDefaults.renderSettings && configDefaults.renderSettings.miniLq && configDefaults.renderSettings.miniLq.footer);

export const getFiosInquire = () => getConfig().fiosInquire || configDefaults.fiosInquire;

export const getHomeLQSettings = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isSMLP_QP = urlSearchParams.get('smlpLQ') === 'Y';
  return isSMLP_QP ? configDefaults?.renderSettings?.homeLQ : getRenderSettings()?.homeLQ;
};

export const getTimerSessionValue = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const timer_exists = urlSearchParams.get('timer_exists');
  const splitItems = timer_exists?.split('_');
  if (splitItems?.length > 1) {
    const item1 = Number(splitItems[0]);
    const item2 = Number(splitItems[1]);
    const isnotNumber1 = Number.isNaN(item1);
    const isnotNumber2 = Number.isNaN(item2);

    if (isnotNumber1 || isnotNumber2) {
      return null;
    }

    return { warning_val: parseInt(splitItems[0]), expiry_val: parseInt(splitItems[1]) };
  }
  return null;
};

export const canApplySessionTimeout = (loopQualApp, Flowtype) =>
  loopQualApp &&
  !loopQualApp.isFccFlow &&
  getFlowType() !== Flowtype.storeLocater &&
  getFlowType() !== Flowtype.retail &&
  getSourceInfo()?.toUpperCase() !== 'CPC' &&
  !loopQualApp.isComboMFJT &&
  !loopQualApp.isECComboMFJT &&
  !window.location.pathname.includes('inhome/checkavailability') &&
  !loopQualApp.newConnection;

export const checkIsHomeInternet = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isHomeInternet = urlSearchParams.get('is_home_int') === 'Y';
  const isMoving = urlSearchParams.get('type') === 'moving';
  const isLocalHost = window.location.href.indexOf('localhost') > -1;
  return (
    (window.location.pathname === '/home/internet' || window.location.pathname === '/home/internet/' || isHomeInternet || isLocalHost) && !isMoving
  );
};

export const checkStreamlineOrderingParam = (cdlThrottleList = '') => {
  const soTreat = 'LQSO_T_Streamline_P';
  const soControl = 'LQSO_C_Streamline_P';
  const soFTreat = 'LQSO_T_Streamline_P_Fios';
  const soFControl = 'LQSO_C_Streamline_P_Fios';
  const cdlThrottListValues = cdlThrottleList?.split?.('|');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const soFlow = urlSearchParams.get('soFlow');
  // queryparam so
  const queryTreat = (soFlow === soTreat && soTreat) || (soFlow === soFTreat && soFTreat);
  const queryControl = (soFlow === soControl && soControl) || (soFlow === soFControl && soFControl);

  // cdl so
  const isCdlTreat = (cdlThrottListValues?.includes(soFTreat) && soFTreat) || (cdlThrottListValues?.includes(soTreat) && soTreat);
  const isCdlControl = (cdlThrottListValues?.includes(soFControl) && soFControl) || (cdlThrottListValues?.includes(soControl) && soControl);

  return queryTreat || queryControl || isCdlTreat || isCdlControl;
};

export default getConfig();
