import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import Config from './config';

let widgetComponent = null;
function app() {
  console.log(widgetComponent);
  widgetComponent = React.createRef();
  ReactDOM.render(
      <App />,
    document.getElementById(Config.targetElementId),
  );

}

app(window);

export default app;
