const eventTypes = ['keypress', 'mousemove', 'mousedown', 'scroll', 'touchmove'];
export const addEventListeners = (listener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
    // Adding to capture all events in screen
    document.addEventListener(type, listener, false);
  });
};
export const removeEventListeners = (listener) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false);
      document.removeEventListener(type, listener, false);
    });
  }
};
